<!-- client/src/components/common/UploadChecklistItemComponent.vue -->

<template>
  <div>
    <div
      class="dropzone dz-clickable d-flex align-items-center px-4 mb-2"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="handleDrop"
      @click="triggerFileInput"
      :class="{
        dragging: isDragging,
        'bg-danger-subtle': hasErrors,
        'bg-success-subtle': fileUrl,
        'text-success-emphasis': fileUrl,
      }"
    >
      <input
        class="form-control visually-hidden"
        type="file"
        @change="handleFileUpload"
        ref="fileInput"
      />
      <div class="dropzone-content">
        <div class="d-flex align-items-center">
          <div class="upload-icon">
            <font-awesome-icon v-if="isLoading" icon="spinner" spin />
            <font-awesome-icon
              v-else
              :icon="fileUrl ? 'check' : 'cloud-upload'"
            />
          </div>
          <div class="text-start">
            <p>{{ uploadTitle }}</p>
            <small>{{ supportedFormats }}</small>
          </div>
        </div>
      </div>
    </div>
    <div
      class="invalid-feedback mb-2 d-flex justify-content-center align-items-center"
      v-if="wrongFileError"
    >
      <div>
        <strong>{{
          $t("signup.grid_contract_upload.wrong_file_error")
        }}</strong>
        <div>{{ $t("signup.grid_contract_upload.contact_support") }}</div>
      </div>
    </div>
    <div
      class="invalid-feedback mb-2 d-flex justify-content-center align-items-center"
      v-for="error of v$.localFileUrl.$errors"
      :key="error.$uid"
    >
      <strong>{{ error.$message }}</strong>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, ref } from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { useValidators } from "@/composables/useValidators";
import { useVuelidate } from "@vuelidate/core";
import { useI18n } from "vue-i18n";
import { computed } from "vue";

interface QueryParams {
  [key: string]: any;
}

export default defineComponent({
  name: "UploadChecklistItemComponent",
  props: {
    uploadTitle: {
      type: String,
      required: true,
    },
    supportedFormats: {
      type: String,
      required: true,
    },
    uploadUrl: {
      type: String,
      required: true,
    },
    accountId: {
      type: Number,
      required: true,
    },
    queryParams: {
      type: Object as () => QueryParams,
      required: true,
    },
    fileUrl: {
      type: String,
      required: true,
    },
  },
  emits: ["uploadComplete", "errorOccured"],
  setup(props, { emit }) {
    const isDragging = ref(false);
    const isLoading = ref(false);
    const wrongFileError = ref(false);
    const fileInput = ref<HTMLInputElement | null>(null);
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const localFileUrl = computed(() => props.fileUrl);

    const processFile = async (file: File) => {
      try {
        isLoading.value = true;
        wrongFileError.value = false;
        const formData = new FormData();
        formData.append("file", file);

        // Add each field from metadata into the formData
        for (const [key, value] of props.queryParams.entries()) {
          formData.append(key.toString(), value);
        }

        const response = await goSolidApi.post(props.uploadUrl, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (response.status === 200 && response.data) {
          emit("uploadComplete", response.data);
        } else {
          throw new Error("Error uploading the document");
        }
      } catch (error: any) {
        if (error.response.data.error === "Uploaded file is not valid") {
          wrongFileError.value = true;
        } else if (error.response) {
          globalErrorHandler(error.response.data);
        } else {
          globalErrorHandler(error);
        }
      } finally {
        isLoading.value = false;
      }
    };

    const handleFileUpload = (event: Event) => {
      const input = event.target as HTMLInputElement;
      const file = input.files?.[0];
      if (file) processFile(file);
    };

    const dragOver = () => (isDragging.value = true);
    const dragLeave = () => (isDragging.value = false);

    const handleDrop = (event: DragEvent) => {
      const file = event.dataTransfer?.files[0];
      if (file) processFile(file);
      isDragging.value = false;
    };

    const triggerFileInput = () => fileInput.value?.click();
    const { t } = useI18n();
    const { fileRequired } = useValidators(t);
    const v$ = useVuelidate(
      { localFileUrl: { fileRequired } },
      { localFileUrl }
    );

    const hasErrors = computed(() => v$.value.localFileUrl.$error);

    return {
      handleFileUpload,
      isDragging,
      isLoading,
      dragOver,
      dragLeave,
      handleDrop,
      triggerFileInput,
      fileInput,
      wrongFileError,
      hasErrors,
      v$,
    };
  },
});
</script>

<style scoped lang="scss">
.dropzone {
  border: 3px dashed var(--phoenix-secondary-text-emphasis);
  border-radius: 10px;
  height: 80px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--phoenix-text-color);
  transition-duration: 200ms;
  transition-property: background-color;
  transition-timing-function: ease-out;

  &.bg-success-subtle {
    border: 1px solid var(--phoenix-success-text-emphasis);
  }

  &.dragging {
    background-color: #e9ecef;
  }

  &:hover {
    background-color: var(--phoenix-primary-bg-subtle);
    color: var(--phoenix-primary-text-emphasis);
    transition-duration: 200ms;
    transition-property: background-color;
    transition-timing-function: ease-out;
  }

  .dropzone-content {
    text-align: center;

    .upload-icon {
      font-size: 1.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 1rem;
    }

    p {
      font-weight: bold;
      font-size: 1.2rem;
      margin: 0;
    }

    small {
      display: block;
      font-size: 0.8rem;
      margin-top: 2px;
    }
  }
}
</style>
