import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d56e84c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card mb-2 generator-card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["data-bs-target", "aria-controls"]
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = {
  key: 0,
  class: "alert alert-subtle-warning text-center admin-extra-info"
}
const _hoisted_7 = { class: "card-subtitle mb-3" }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
const _hoisted_10 = ["href"]
const _hoisted_11 = ["id", "aria-labelledby"]
const _hoisted_12 = { class: "card-text" }
const _hoisted_13 = { class: "accordion border rounded p-2" }
const _hoisted_14 = { class: "accordion-item" }
const _hoisted_15 = { class: "accordion-body" }
const _hoisted_16 = { class: "generator-property" }
const _hoisted_17 = { class: "generator-value" }
const _hoisted_18 = {
  key: 0,
  class: "badge rounded-pill bg-secondary text-white"
}
const _hoisted_19 = {
  key: 0,
  class: "mb-3 mt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_DocumentCardComponent = _resolveComponent("DocumentCardComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("h2", {
          class: "accordion-header",
          id: 'heading' + _ctx.generator.id
        }, [
          _createElementVNode("a", {
            href: "#",
            class: "collapsed",
            type: "accordion-button button",
            "data-bs-toggle": "collapse",
            "data-bs-target": '#collapse' + _ctx.generator.id,
            "aria-expanded": "false",
            "aria-controls": 'collapse' + _ctx.generator.id
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createTextVNode(_toDisplayString(_ctx.$t("generator.EIC")) + ": " + _toDisplayString(_ctx.generator.device_eic) + " ", 1),
              (_ctx.is_admin)
                ? (_openBlock(), _createElementBlock("label", _hoisted_6, _toDisplayString(_ctx.generator.id), 1))
                : _createCommentVNode("", true)
            ])
          ], 8, _hoisted_4)
        ], 8, _hoisted_3),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", null, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.generatorStatusClass)
            }, _toDisplayString(_ctx.$t(`generator.status.${_ctx.generatorStatusState}`)), 3),
            (_ctx.generatorStatusState === _ctx.GeneratorStatus.Unregistered)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: `/signup?step=2&generator_id=${_ctx.generator.id}&company_id=${_ctx.generator.company_id}`,
                  class: "link"
                }, " (" + _toDisplayString(_ctx.$t("generator.continue_registration")) + ") ", 9, _hoisted_8))
              : _createCommentVNode("", true),
            (
                _ctx.generatorStatusState === _ctx.GeneratorStatus.PendingSignature &&
                _ctx.generatorDocumentToSign
              )
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  href: _ctx.generatorDocumentToSign,
                  class: "btn btn-link p-0",
                  target: "_blank"
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    class: "px-1",
                    icon: "signature"
                  }),
                  _createTextVNode(" (" + _toDisplayString(_ctx.$t("generator.sign_document")) + ") ", 1)
                ], 8, _hoisted_9))
              : _createCommentVNode("", true),
            (_ctx.is_admin)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 2,
                  class: "alert alert-subtle-warning text-center admin-extra-info",
                  href: `/signup?step=2&generator_id=${_ctx.generator.id}&company_id=${_ctx.generator.company_id}`
                }, _toDisplayString(_ctx.$t("common.edit")), 9, _hoisted_10))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", {
          id: 'collapse' + _ctx.generator.id,
          class: "accordion-collapse collapse",
          "aria-labelledby": 'heading' + _ctx.generator.id
        }, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prop_values, (propval) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: propval.property,
                      class: "generator-segment"
                    }, [
                      _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t(`generator.properties.${propval.property}`)) + ": ", 1),
                      _createElementVNode("div", _hoisted_17, _toDisplayString(propval.value), 1),
                      (!propval.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.$t("common.unspecified")), 1))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ])
              ])
            ])
          ]),
          (_ctx.generatorDocuments.length > 0)
            ? (_openBlock(), _createElementBlock("h4", _hoisted_19, _toDisplayString(_ctx.$t("generator.documents")), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.generatorDocuments, (document) => {
            return (_openBlock(), _createElementBlock("div", {
              key: document.id,
              class: "card mb-2"
            }, [
              _createVNode(_component_DocumentCardComponent, {
                title: `${_ctx.$t('generator.document_title', {
                eic: _ctx.generator.device_eic,
              })}`,
                document: document,
                account_id: _ctx.account_id,
                is_admin: _ctx.is_admin
              }, null, 8, ["title", "document", "account_id", "is_admin"])
            ]))
          }), 128))
        ], 8, _hoisted_11)
      ])
    ])
  ]))
}