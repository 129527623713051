import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock, vModelSelect as _vModelSelect, vModelRadio as _vModelRadio, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f7cdf4c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "dashboard-mobile-header",
  class: "navbar navbar-dark d-md-none"
}
const _hoisted_2 = { class: "container-fluid justify-content-between" }
const _hoisted_3 = { class: "mb-0 company-name" }
const _hoisted_4 = { id: "dashboard-sidebar" }
const _hoisted_5 = { class: "nav-title" }
const _hoisted_6 = { class: "company-list" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "alert alert-subtle-warning text-center admin-extra-info"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["href"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "sidebar-call-to-action-title" }
const _hoisted_13 = ["href"]
const _hoisted_14 = {
  key: 0,
  class: "alert alert-subtle-warning text-center"
}
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = {
  id: "dashboard-wrapper",
  class: "pl-sm-3"
}
const _hoisted_17 = {
  id: "dashboard-content",
  class: "flex-grow-1 pt-4"
}
const _hoisted_18 = {
  key: 1,
  class: "alert alert-subtle-warning text-center"
}
const _hoisted_19 = { class: "form-group" }
const _hoisted_20 = { value: "user" }
const _hoisted_21 = { value: "super_admin" }
const _hoisted_22 = { class: "form-group" }
const _hoisted_23 = { class: "d-flex align-items-center" }
const _hoisted_24 = { value: "standard" }
const _hoisted_25 = { value: "special_free" }
const _hoisted_26 = { value: "special_1eur_per_mw" }
const _hoisted_27 = { value: "custom_percentage" }
const _hoisted_28 = {
  key: 1,
  class: "ml-2"
}
const _hoisted_29 = {
  key: 0,
  class: "form-text text-muted"
}
const _hoisted_30 = { class: "form-group" }
const _hoisted_31 = { class: "form-group" }
const _hoisted_32 = { class: "form-group" }
const _hoisted_33 = { class: "form-group" }
const _hoisted_34 = { for: "testYes" }
const _hoisted_35 = { for: "testNo" }
const _hoisted_36 = { class: "admin-trackings" }
const _hoisted_37 = { class: "table table-hover" }
const _hoisted_38 = ["onUpdate:modelValue"]
const _hoisted_39 = ["onClick"]
const _hoisted_40 = { for: "companySelect" }
const _hoisted_41 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_company_data_component = _resolveComponent("company-data-component")!
  const _component_account_data_debug_component = _resolveComponent("account-data-debug-component")!

  return (_openBlock(), _createElementBlock("div", {
    id: "dashboard",
    class: _normalizeClass({ 'd-flex': true, 'sidebar-open': _ctx.isSidebarOpen })
  }, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.selectedCompanyOrFirst
              ? _ctx.selectedCompanyOrFirst.name
              : _ctx.$t("dashboard.select_company")), 1),
        _createElementVNode("button", {
          class: "navbar-toggler",
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSidebar && _ctx.toggleSidebar(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "caret-down" })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        type: "button",
        class: "btn-close btn-close-white",
        "aria-label": "Close",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSidebar && _ctx.toggleSidebar(...args)))
      }),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("dashboard.companies")), 1),
      _createElementVNode("ul", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accountData.companies, (company) => {
          return (_openBlock(), _createElementBlock("li", {
            key: company.id,
            class: _normalizeClass({
            'nav-item': true,
            'is-selected-nav': _ctx.selectedCompanyOrFirst?.id === company?.id,
          })
          }, [
            _createElementVNode("a", {
              class: "nav-link",
              onClick: ($event: any) => (_ctx.selectCompany(company.id!)),
              role: "button"
            }, [
              _createTextVNode(_toDisplayString(company.name) + " ", 1),
              (_ctx.accountData.admin_account_id)
                ? (_openBlock(), _createElementBlock("label", _hoisted_8, _toDisplayString(company.id), 1))
                : _createCommentVNode("", true)
            ], 8, _hoisted_7),
            (_ctx.getDocumentsReadyToSign(company).length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getDocumentsReadyToSign(company)[0]
                .document_signers, (signer) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: signer.id,
                      class: "sidebar-call-to-action"
                    }, [
                      _createElementVNode("a", {
                        href: signer.redirect_url,
                        class: "btn btn-subtle-primary btn-sm sidebar-call-to-action-button"
                      }, [
                        _createVNode(_component_font_awesome_icon, {
                          class: "px-1",
                          icon: "signature"
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("dashboard.sign_document")), 1)
                      ], 8, _hoisted_10)
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(company.generators.filter(
                (generator) => !generator.has_registered
              ), (generator) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: generator.id,
                      class: "sidebar-call-to-action"
                    }, [
                      _createElementVNode("div", _hoisted_12, " EIC: " + _toDisplayString(generator.device_eic), 1),
                      _createElementVNode("a", {
                        href: `/signup?step=2&generator_id=${generator.id}&company_id=${generator.company_id}`,
                        class: "btn btn-subtle-primary btn-sm sidebar-call-to-action-button"
                      }, [
                        _createVNode(_component_font_awesome_icon, {
                          class: "px-1",
                          icon: "play"
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("dashboard.continue_registration")), 1)
                      ], 8, _hoisted_13)
                    ]))
                  }), 128))
                ]))
          ], 2))
        }), 128)),
        _createElementVNode("button", {
          class: "btn btn-link",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goToRegisterNewDevice && _ctx.goToRegisterNewDevice(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "plus" }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("dashboard.add_company_or_device")), 1)
        ])
      ]),
      (_ctx.accountData.admin_account_id)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t("dashboard.add_company_access")), 1),
              _withDirectives(_createElementVNode("input", {
                type: "number",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newCompanyId) = $event)),
                class: "form-control",
                placeholder: "$t(\"dashboard.company_id\")"
              }, null, 512), [
                [_vModelText, _ctx.newCompanyId]
              ])
            ]),
            _createElementVNode("button", {
              class: "btn btn-primary",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.confirmAddCompany && _ctx.confirmAddCompany(...args)))
            }, _toDisplayString(_ctx.$t("dashboard.add_company")), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        (_ctx.accountData && _ctx.accountData.id && _ctx.selectedCompanyOrFirst)
          ? (_openBlock(), _createBlock(_component_company_data_component, {
              company: _ctx.selectedCompanyOrFirst,
              "onUpdate:company": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedCompanyOrFirst) = $event)),
              account_id: _ctx.accountData.id,
              is_admin: !!_ctx.accountData.admin_account_id,
              key: _ctx.selectedCompanyOrFirst.id
            }, null, 8, ["company", "account_id", "is_admin"]))
          : _createCommentVNode("", true),
        _createVNode(_component_account_data_debug_component, {
          accountData: _ctx.accountData,
          class: "mb-4"
        }, null, 8, ["accountData"]),
        (_ctx.accountData.admin_account_id && _ctx.selectedCompanyOrFirst)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t("dashboard.internal_role")), 1),
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.accountData.internal_role) = $event)),
                  class: "form-control"
                }, [
                  _createElementVNode("option", _hoisted_20, _toDisplayString(_ctx.$t("dashboard.user")), 1),
                  _createElementVNode("option", _hoisted_21, _toDisplayString(_ctx.$t("dashboard.super_admin")), 1)
                ], 512), [
                  [_vModelSelect, _ctx.accountData.internal_role]
                ])
              ]),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t("dashboard.fee_model")), 1),
                _createElementVNode("div", _hoisted_23, [
                  _withDirectives(_createElementVNode("select", {
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedFeeModel) = $event)),
                    class: "form-control mr-2",
                    onChange: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.updateFeeModel && _ctx.updateFeeModel(...args)))
                  }, [
                    _createElementVNode("option", _hoisted_24, _toDisplayString(_ctx.$t("dashboard.standard")), 1),
                    _createElementVNode("option", _hoisted_25, _toDisplayString(_ctx.$t("dashboard.special_free")), 1),
                    _createElementVNode("option", _hoisted_26, _toDisplayString(_ctx.$t("dashboard.special_1eur_per_mw")), 1),
                    _createElementVNode("option", _hoisted_27, _toDisplayString(_ctx.$t("dashboard.custom_percentage")), 1)
                  ], 544), [
                    [_vModelSelect, _ctx.selectedFeeModel]
                  ]),
                  (_ctx.selectedFeeModel === 'custom_percentage')
                    ? _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: 0,
                        type: "number",
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.customPercentage) = $event)),
                        class: "form-control ml-2",
                        min: "5",
                        max: "20",
                        step: "1",
                        onInput: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.updateCustomPercentage && _ctx.updateCustomPercentage(...args)))
                      }, null, 544)), [
                        [
                          _vModelText,
                          _ctx.customPercentage,
                          void 0,
                          { number: true }
                        ]
                      ])
                    : _createCommentVNode("", true),
                  (_ctx.selectedFeeModel === 'custom_percentage')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_28, "%"))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.selectedFeeModel === 'custom_percentage')
                  ? (_openBlock(), _createElementBlock("small", _hoisted_29, _toDisplayString(_ctx.$t("dashboard.custom_percentage_hint")), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t("dashboard.registry_excluded_devices")), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.selectedCompanyOrFirst!.registry_excluded_devices) = $event)),
                  class: "form-control"
                }, null, 512), [
                  [_vModelText, _ctx.selectedCompanyOrFirst!.registry_excluded_devices]
                ])
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t("dashboard.nda_signed_link")), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.selectedCompanyOrFirst!.nda_signed_link) = $event)),
                  class: "form-control"
                }, null, 512), [
                  [_vModelText, _ctx.selectedCompanyOrFirst!.nda_signed_link]
                ])
              ]),
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t("account.comment")), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.accountData.comment) = $event)),
                  class: "form-control"
                }, null, 512), [
                  [_vModelText, _ctx.accountData.comment]
                ])
              ]),
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t("dashboard.is_test_company")), 1),
                _createElementVNode("div", null, [
                  _withDirectives(_createElementVNode("input", {
                    type: "radio",
                    id: "testYes",
                    value: "true",
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.selectedCompanyOrFirst!.is_test_company) = $event))
                  }, null, 512), [
                    [_vModelRadio, _ctx.selectedCompanyOrFirst!.is_test_company]
                  ]),
                  _createElementVNode("label", _hoisted_34, _toDisplayString(_ctx.$t("common.yes")), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "radio",
                    id: "testNo",
                    value: "false",
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.selectedCompanyOrFirst!.is_test_company) = $event))
                  }, null, 512), [
                    [_vModelRadio, _ctx.selectedCompanyOrFirst!.is_test_company]
                  ]),
                  _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.$t("common.no")), 1)
                ])
              ]),
              _createElementVNode("button", {
                class: "btn btn-primary",
                onClick: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.updateAccountData && _ctx.updateAccountData(...args)))
              }, _toDisplayString(_ctx.$t("dashboard.save_changes")), 1),
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t("dashboard.all_trackings")), 1),
                _createElementVNode("table", _hoisted_37, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", null, _toDisplayString(_ctx.$t("common.id")), 1),
                      _createElementVNode("th", null, _toDisplayString(_ctx.$t("dashboard.referrer_id")), 1),
                      _createElementVNode("th", null, _toDisplayString(_ctx.$t("dashboard.utm_source")), 1),
                      _createElementVNode("th", null, _toDisplayString(_ctx.$t("dashboard.utm_medium")), 1),
                      _createElementVNode("th", null, _toDisplayString(_ctx.$t("dashboard.utm_term")), 1),
                      _createElementVNode("th", null, _toDisplayString(_ctx.$t("dashboard.utm_content")), 1),
                      _createElementVNode("th", null, _toDisplayString(_ctx.$t("dashboard.utm_campaign")), 1),
                      _createElementVNode("th", null, _toDisplayString(_ctx.$t("dashboard.created_at")), 1)
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accountData.trackings, (tracking) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: tracking.id
                      }, [
                        _createElementVNode("td", null, _toDisplayString(tracking.id), 1),
                        _createElementVNode("td", null, [
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            "onUpdate:modelValue": ($event: any) => ((tracking.referrer_id) = $event),
                            class: "form-control"
                          }, null, 8, _hoisted_38), [
                            [_vModelText, tracking.referrer_id]
                          ]),
                          _createElementVNode("button", {
                            class: "btn btn-primary",
                            onClick: ($event: any) => (_ctx.updateTracking(tracking))
                          }, _toDisplayString(_ctx.$t("dashboard.save_changes")), 9, _hoisted_39)
                        ]),
                        _createElementVNode("td", null, _toDisplayString(tracking.utm_source), 1),
                        _createElementVNode("td", null, _toDisplayString(tracking.utm_medium), 1),
                        _createElementVNode("td", null, _toDisplayString(tracking.utm_term), 1),
                        _createElementVNode("td", null, _toDisplayString(tracking.utm_content), 1),
                        _createElementVNode("td", null, _toDisplayString(tracking.utm_campaign), 1),
                        _createElementVNode("td", null, _toDisplayString(tracking.created_at), 1)
                      ]))
                    }), 128))
                  ])
                ])
              ]),
              _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.$t("dashboard.admin_only_transfer_and_delete_company")), 1),
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.selectedTransferCompanyId) = $event)),
                id: "companySelect",
                class: "form-control"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accountData.companies, (company) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: company.id,
                    value: company.id
                  }, _toDisplayString(_ctx.$t("dashboard.transfer_to", {
                  name: company.name,
                  id: company.id,
                })), 9, _hoisted_41))
                }), 128))
              ], 512), [
                [_vModelSelect, _ctx.selectedTransferCompanyId]
              ]),
              _createElementVNode("button", {
                class: "btn btn-primary mt-2",
                onClick: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.confirmTransfer && _ctx.confirmTransfer(...args)))
              }, _toDisplayString(_ctx.$t("dashboard.transfer")), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}