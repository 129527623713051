import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2127b91b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropzone-content" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "upload-icon" }
const _hoisted_4 = { class: "text-start" }
const _hoisted_5 = {
  key: 0,
  class: "invalid-feedback mb-2 d-flex justify-content-center align-items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["dropzone dz-clickable d-flex align-items-center px-4 mb-2", {
        dragging: _ctx.isDragging,
        'bg-danger-subtle': _ctx.hasErrors,
        'bg-success-subtle': _ctx.fileUrl,
        'text-success-emphasis': _ctx.fileUrl,
      }]),
      onDragover: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.dragOver && _ctx.dragOver(...args)), ["prevent"])),
      onDragleave: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.dragLeave && _ctx.dragLeave(...args)), ["prevent"])),
      onDrop: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleDrop && _ctx.handleDrop(...args)), ["prevent"])),
      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.triggerFileInput && _ctx.triggerFileInput(...args)))
    }, [
      _createElementVNode("input", {
        class: "form-control visually-hidden",
        type: "file",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFileUpload && _ctx.handleFileUpload(...args))),
        ref: "fileInput"
      }, null, 544),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 0,
                  icon: "spinner",
                  spin: ""
                }))
              : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 1,
                  icon: _ctx.fileUrl ? 'check' : 'cloud-upload'
                }, null, 8, ["icon"]))
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", null, _toDisplayString(_ctx.uploadTitle), 1),
            _createElementVNode("small", null, _toDisplayString(_ctx.supportedFormats), 1)
          ])
        ])
      ])
    ], 34),
    (_ctx.wrongFileError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", null, [
            _createElementVNode("strong", null, _toDisplayString(_ctx.$t("signup.grid_contract_upload.wrong_file_error")), 1),
            _createElementVNode("div", null, _toDisplayString(_ctx.$t("signup.grid_contract_upload.contact_support")), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.localFileUrl.$errors, (error) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "invalid-feedback mb-2 d-flex justify-content-center align-items-center",
        key: error.$uid
      }, [
        _createElementVNode("strong", null, _toDisplayString(error.$message), 1)
      ]))
    }), 128))
  ]))
}