<!-- client/src/views/admin/DashboardView.vue -->

<template>
  <div id="AdminDashboard" class="container">
    <div class="row justify-content-between align-items-center mb-3 mt-4">
      <div class="col-auto">
        <h1>Admin Dashboard</h1>
      </div>
    </div>
    <a href="/admin/accounts" class="alert-link">Admin Accounts </a>
    <a href="/admin/emails" class="alert-link">Admin Emails </a>
    <div class="row">
      <div class="col-md-6">
        <template
          v-for="(yearData, year) in groupByYear(
            certificatedGroupedByFuelAndPriceAskAndMonth
          )"
          :key="year"
        >
          <div>
            <h2>{{ year }} Certificates Summary</h2>
            <div class="col-12">
              <div class="table-container table-responsive">
                <table class="table table-hover align-middle p-2">
                  <thead>
                    <tr>
                      <th>Month</th>
                      <th>Supported</th>
                      <th>Not Supported</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong>{{ year }} Total</strong>
                      </td>
                      <td>
                        <strong>{{
                          calculateYearlyTotalSupported(yearData)
                        }}</strong>
                      </td>
                      <td>
                        <strong>{{
                          calculateYearlyTotalUnsupported(yearData)
                        }}</strong>
                      </td>
                    </tr>
                    <template
                      v-for="(totals, price) in calculateYearlyTotalByPrice(
                        yearData
                      )"
                      :key="`${year}-${price}`"
                    >
                      <tr>
                        <td>- Total {{ displayPriceRow(price as string) }}</td>
                        <td>{{ totals.supported }}</td>
                        <td>{{ totals.unsupported }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
            <br />
            <br />
          </div>
        </template>
        <template
          v-for="(yearData, year) in groupByYear(
            certificatedGroupedByFuelAndPriceAskAndMonth
          )"
          :key="year"
        >
          <div>
            <h2>{{ year }} Certificates</h2>
            <div v-for="(fuelBreakdown, fuelCode) in yearData" :key="fuelCode">
              <div class="col-12">
                <h3>{{ getFuelCodeName(fuelCode) }}</h3>
                <div class="table-container table-responsive">
                  <table class="table table-hover align-middle p-2">
                    <thead>
                      <tr>
                        <th>Month</th>
                        <th>Supported</th>
                        <th>Not Supported</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                        v-for="(monthBreakdown, month) in fuelBreakdown"
                        :key="`${month}-total`"
                      >
                        <tr>
                          <td>
                            <strong>{{ month }} Total</strong>
                          </td>
                          <td>
                            <strong>{{
                              calculateTotalSupported(monthBreakdown)
                            }}</strong>
                          </td>
                          <td>
                            <strong>{{
                              calculateTotalUnsupported(monthBreakdown)
                            }}</strong>
                          </td>
                        </tr>
                        <template
                          v-for="(priceBreakdown, price) in monthBreakdown"
                          :key="`${month}-${price}`"
                        >
                          <tr v-if="Object.keys(monthBreakdown).length > 1">
                            <td>- {{ displayPriceRow(price as string) }}</td>
                            <td>{{ priceBreakdown.supported }}</td>
                            <td>{{ priceBreakdown.unsupported }}</td>
                          </tr>
                        </template>
                      </template>
                      <tr>
                        <td>
                          <strong
                            >Total for {{ getFuelCodeName(fuelCode) }}
                            {{ year }}</strong
                          >
                        </td>
                        <td>
                          <strong>{{
                            calculateTotalSupportedFuel(fuelBreakdown)
                          }}</strong>
                        </td>
                        <td>
                          <strong>{{
                            calculateTotalUnsupportedFuel(fuelBreakdown)
                          }}</strong>
                        </td>
                      </tr>
                      <template
                        v-for="(
                          totals, price
                        ) in calculateTotalSupportedFuelByPrice(fuelBreakdown)"
                        :key="`${fuelCode}-${year}-${price}`"
                      >
                        <tr>
                          <td>- Total {{ displayPriceRow(price) }}</td>
                          <td>{{ totals.supported }}</td>
                          <td>{{ totals.unsupported }}</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <br />
                <br />
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="col-md-6">
        <div class="chart-container">
          <div class="chart-title">
            <h4>GOs for Sale (Last 12 Months)</h4>
            <p>Total: {{ totalGOs }}</p>
            <p>Supported: {{ supportedGOs }}</p>
            <p>Not Supported: {{ unsupportedGOs }}</p>
          </div>
          <canvas ref="chartRef"></canvas>

          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Stats</h5>
              <p class="card-text">
                Total Accounts: {{ statistics.account_count }}
              </p>
              <p class="card-text">
                Total Companies: {{ statistics.company_count }}
              </p>
              <p class="card-text">
                With Generators:
                {{ statistics.companies_with_generators_count }}
              </p>
              <p class="card-text">
                With Registry Devices:
                {{ statistics.companies_with_registry_devices_count }}
              </p>
              <p class="card-text">
                With Certificates:
                {{ statistics.companies_with_certificates_count }}
              </p>
              <p class="card-text">
                Total Generators: {{ statistics.total_generators }}
              </p>
              <p class="card-text">
                Total Registry Devices: {{ statistics.total_registry_devices }}
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Power MW</h5>
              <p class="card-text">
                Uploaded Generator Power:
                {{ (statistics.total_generator_power / 1000).toFixed(2) }} MW
              </p>
              <p class="card-text">
                Signed Generator Power:
                {{ (statistics.registered_generator_power / 1000).toFixed(2) }}
                MW
              </p>
              <p></p>
              <p class="card-text">
                Device Power in Registry:
                {{ (statistics.total_registry_device_power / 1000).toFixed(2) }}
                MW
              </p>
              <p class="card-text">
                - Solar Power in Registry:
                {{ (statistics.solar_registry_device_power / 1000).toFixed(2) }}
                MW
              </p>
              <p class="card-text">
                - Wind Power in Registry:
                {{ (statistics.wind_registry_device_power / 1000).toFixed(2) }}
                MW
              </p>
              <p></p>
              <p class="card-text">
                Predicted production:
                {{
                  (
                    (statistics.solar_registry_device_power +
                      statistics.wind_registry_device_power * 3) /
                    1000
                  ).toFixed(2)
                }}
                GWh
              </p>
              <p class="card-text">
                - Predicted Solar production:
                {{ (statistics.solar_registry_device_power / 1000).toFixed(2) }}
                GWh
              </p>
              <p class="card-text">
                - Predicted Wind production:
                {{
                  ((statistics.wind_registry_device_power * 3) / 1000).toFixed(
                    2
                  )
                }}
                GWh
              </p>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Power by Country (MW) in registry</h5>
              <div
                v-for="(power, country) in statistics.power_summary_by_country"
                :key="country"
              >
                <h6>{{ country }} MW power</h6>
                <p class="card-text">
                  Total Power:
                  {{
                    ((power.solar_power + power.wind_power) / 1000).toFixed(2)
                  }}
                  MW
                </p>
                <p class="card-text">
                  Solar Power: {{ (power.solar_power / 1000).toFixed(2) }} MW
                </p>
                <p class="card-text">
                  Wind Power: {{ (power.wind_power / 1000).toFixed(2) }} MW
                </p>
                <hr
                  v-if="
                    Object.keys(statistics.power_summary_by_country).length > 1
                  "
                />

                <h6>{{ country }} GWh predicted production</h6>

                <p class="card-text">
                  Total Power:
                  {{
                    ((power.solar_power + power.wind_power * 3) / 1000).toFixed(
                      2
                    )
                  }}
                  GWh
                </p>
                <p class="card-text">
                  Solar Power: {{ (power.solar_power / 1000).toFixed(2) }} GWh
                </p>
                <p class="card-text">
                  Wind Power:
                  {{ ((power.wind_power * 3) / 1000).toFixed(2) }} GWh
                </p>
                <hr
                  v-if="
                    Object.keys(statistics.power_summary_by_country).length > 1
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, ref, computed } from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";
import Chart from "chart.js/auto";

interface PriceAsk {
  supported: number;
  unsupported: number;
}

// New interface to handle the breakdown by price for a specific month
interface MonthBreakdown {
  [price_ask: string]: PriceAsk;
}

interface FuelCodeBreakdown {
  [month: string]: MonthBreakdown;
}

interface CertificateBreakdown {
  [fuelCode: string]: FuelCodeBreakdown;
}

interface CountryPower {
  solar_power: number;
  wind_power: number;
}

interface Country {
  [country: string]: CountryPower;
}

interface Statistics {
  account_count: number;
  company_count: number;
  companies_with_generators_count: number;
  companies_with_registry_devices_count: number;
  companies_with_certificates_count: number;
  total_generators: number;
  total_registry_devices: number;
  total_generator_power: number;
  registered_generator_power: number;
  total_registry_device_power: number;
  solar_registry_device_power: number;
  wind_registry_device_power: number;
  power_summary_by_country: Country;
}

interface FuelCodeColor {
  supported: string;
  unsupported: string;
}

export default defineComponent({
  methods: {
    calculateTotalSupportedAtPrice(
      monthBreakdown: MonthBreakdown,
      price: string
    ): number {
      return monthBreakdown[price].supported;
    },
    calculateTotalUnsupportedAtPrice(
      monthBreakdown: MonthBreakdown,
      price: string
    ): number {
      return monthBreakdown[price].unsupported;
    },
    groupByYear(data: CertificateBreakdown) {
      const years: Record<string, CertificateBreakdown> = {};

      Object.keys(data).forEach((fuelCode) => {
        Object.keys(data[fuelCode]).forEach((month) => {
          const year = month.substring(0, 4); // Assuming month is in 'YYYY-MM' format
          if (!years[year]) {
            years[year] = {};
          }
          if (!years[year][fuelCode]) {
            years[year][fuelCode] = {};
          }
          years[year][fuelCode][month] = data[fuelCode][month];
        });
      });
      return years;
    },
    calculateYearlyTotalSupported(yearData: CertificateBreakdown): number {
      return Object.values(yearData).reduce((totalFuel, fuelBreakdown) => {
        return (
          totalFuel +
          Object.values(fuelBreakdown).reduce((totalMonth, monthBreakdown) => {
            return totalMonth + this.calculateTotalSupported(monthBreakdown);
          }, 0)
        );
      }, 0);
    },

    calculateYearlyTotalUnsupported(yearData: CertificateBreakdown): number {
      return Object.values(yearData).reduce((totalFuel, fuelBreakdown) => {
        return (
          totalFuel +
          Object.values(fuelBreakdown).reduce((totalMonth, monthBreakdown) => {
            return totalMonth + this.calculateTotalUnsupported(monthBreakdown);
          }, 0)
        );
      }, 0);
    },

    calculateYearlyTotalByPrice(
      yearData: CertificateBreakdown
    ): Record<string, PriceAsk> {
      const priceTotals: Record<string, PriceAsk> = {};
      Object.values(yearData).forEach((fuelBreakdown) => {
        Object.values(fuelBreakdown).forEach((monthBreakdown) => {
          Object.entries(this.calculateTotalByPrice(monthBreakdown)).forEach(
            ([price, totals]) => {
              if (!priceTotals[price])
                priceTotals[price] = { supported: 0, unsupported: 0 };
              priceTotals[price].supported += totals.supported;
              priceTotals[price].unsupported += totals.unsupported;
            }
          );
        });
      });
      return priceTotals;
    },
    calculateTotalSupportedFuel(fuelBreakdown: FuelCodeBreakdown): number {
      return Object.values(fuelBreakdown).reduce((total, monthBreakdown) => {
        return total + this.calculateTotalSupported(monthBreakdown);
      }, 0);
    },

    calculateTotalUnsupportedFuel(fuelBreakdown: FuelCodeBreakdown): number {
      return Object.values(fuelBreakdown).reduce((total, monthBreakdown) => {
        return total + this.calculateTotalUnsupported(monthBreakdown);
      }, 0);
    },

    calculateTotalSupportedFuelByPrice(
      fuelBreakdown: FuelCodeBreakdown
    ): Record<string, PriceAsk> {
      const priceTotals: Record<string, PriceAsk> = {};
      Object.values(fuelBreakdown).forEach((monthBreakdown) => {
        Object.entries(this.calculateTotalByPrice(monthBreakdown)).forEach(
          ([price, totals]) => {
            if (!priceTotals[price])
              priceTotals[price] = { supported: 0, unsupported: 0 };
            priceTotals[price].supported += totals.supported;
            priceTotals[price].unsupported += totals.unsupported;
          }
        );
      });
      return priceTotals;
    },
  },
  setup() {
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const statistics = ref<Statistics>({
      account_count: 0,
      company_count: 0,
      companies_with_generators_count: 0,
      companies_with_registry_devices_count: 0,
      companies_with_certificates_count: 0,
      total_generators: 0,
      total_registry_devices: 0,
      total_generator_power: 0,
      registered_generator_power: 0,
      total_registry_device_power: 0,
      solar_registry_device_power: 0,
      wind_registry_device_power: 0,
      power_summary_by_country: {},
    });
    const chartRef = ref<HTMLCanvasElement | null>(null);
    const certificatedGroupedByFuelAndPriceAskAndMonth =
      ref<CertificateBreakdown>({});

    const calculateTotalSupported = (
      monthBreakdown: MonthBreakdown
    ): number => {
      return Object.values(monthBreakdown).reduce(
        (totalMonth, { supported }) => {
          return totalMonth + supported;
        },
        0
      );
    };

    const calculateTotalUnsupported = (
      monthBreakdown: MonthBreakdown
    ): number => {
      return Object.values(monthBreakdown).reduce(
        (totalMonth, { unsupported }) => {
          return totalMonth + unsupported;
        },
        0
      );
    };

    const calculateTotalByPrice = (
      monthBreakdown: MonthBreakdown
    ): Record<string, PriceAsk> => {
      const totals: Record<string, PriceAsk> = {};
      Object.entries(monthBreakdown).forEach(([price, breakdown]) => {
        if (!totals[price]) totals[price] = { supported: 0, unsupported: 0 };
        totals[price].supported += breakdown.supported;
        totals[price].unsupported += breakdown.unsupported;
      });
      return totals;
    };

    const displayPriceRow = (price: string): string => {
      if (price === "0.0") return "sell at highest bid";
      return "min ASK at €" + price;
    };

    onMounted(async () => {
      try {
        const response = await goSolidApi.get("/api/admin/dashboard");
        if (response.status === 200 && response.data) {
          certificatedGroupedByFuelAndPriceAskAndMonth.value =
            response.data["certificates_grouped_by_fuel_and_month"];
          statistics.value = response.data["statistics"];
          renderChart();
        } else {
          throw new Error("Error fetching certificates");
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    });

    const getFuelCodeName = (fuelCode: string | number) => {
      const names: { [key: string]: string } = {
        F01040100: "Solar",
        F01050100: "Wind",
      };

      return names[fuelCode] || fuelCode;
    };

    const fuelCodeColors: Record<string, FuelCodeColor> = {
      F01040100: {
        // Solar
        supported: "rgba(255, 159, 64, 1)", // Strong color for supported
        unsupported: "rgba(255, 159, 64, 0.5)", // More transparent color for unsupported
      },
      F01050100: {
        // Wind
        supported: "rgba(54, 162, 235, 1)", // Strong color for supported
        unsupported: "rgba(54, 162, 235, 0.5)", // More transparent color for unsupported
      },
    };

    const renderChart = () => {
      if (chartRef.value) {
        const ctx = chartRef.value.getContext("2d");
        if (ctx) {
          const fuelCodes = Object.keys(
            certificatedGroupedByFuelAndPriceAskAndMonth.value
          );
          const months = new Set<string>();
          // Gather all months across all fuel codes
          fuelCodes.forEach((fuelCode) => {
            Object.keys(
              certificatedGroupedByFuelAndPriceAskAndMonth.value[fuelCode]
            ).forEach((month) => {
              months.add(month);
            });
          });
          const sortedMonths = Array.from(months).sort();

          const datasets = fuelCodes.flatMap((fuelCode) => {
            const fuelCodeName = getFuelCodeName(fuelCode); // Use the method to get the name
            const fuelCodeData =
              certificatedGroupedByFuelAndPriceAskAndMonth.value[fuelCode];

            const supportedData = sortedMonths.map((month) =>
              fuelCodeData[month]
                ? calculateTotalSupported(fuelCodeData[month])
                : 0
            );
            const unsupportedData = sortedMonths.map((month) =>
              fuelCodeData[month]
                ? calculateTotalUnsupported(fuelCodeData[month])
                : 0
            );

            const { supported: supportedColor, unsupported: unsupportedColor } =
              fuelCodeColors[fuelCode] || {
                supported: "rgba(201, 203, 207, 1)",
                unsupported: "rgba(201, 203, 207, 0.5)",
              };

            return [
              {
                label: `${fuelCodeName} - Supported`,
                data: supportedData,
                backgroundColor: supportedColor,
                borderColor: supportedColor,
                borderWidth: 1,
              },
              {
                label: `${fuelCodeName} - Not Supported`,
                data: unsupportedData,
                backgroundColor: unsupportedColor,
                borderColor: unsupportedColor,
                borderWidth: 1,
              },
            ];
          });

          new Chart(ctx, {
            type: "bar",
            data: {
              labels: sortedMonths,
              datasets: datasets,
            },
            options: {
              scales: {
                x: {
                  stacked: false,
                },
                y: {
                  stacked: false,
                  beginAtZero: true,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
            },
          });
        }
      }
    };

    const totalGOs = computed(() => {
      return supportedGOs.value + unsupportedGOs.value;
    });

    const supportedGOs = computed(() => {
      return Object.values(
        certificatedGroupedByFuelAndPriceAskAndMonth.value
      ).reduce((total, fuelBreakdown) => {
        return (
          total +
          Object.values(fuelBreakdown).reduce((monthTotal, monthBreakdown) => {
            return (
              monthTotal +
              Object.values(monthBreakdown).reduce(
                (priceTotal, priceAskBreakdown) => {
                  return priceTotal + priceAskBreakdown.supported;
                },
                0
              )
            );
          }, 0)
        );
      }, 0);
    });

    const unsupportedGOs = computed(() => {
      return Object.values(
        certificatedGroupedByFuelAndPriceAskAndMonth.value
      ).reduce((total, fuelBreakdown) => {
        return (
          total +
          Object.values(fuelBreakdown).reduce((monthTotal, monthBreakdown) => {
            return (
              monthTotal +
              Object.values(monthBreakdown).reduce(
                (priceTotal, priceAskBreakdown) => {
                  return priceTotal + priceAskBreakdown.unsupported;
                },
                0
              )
            );
          }, 0)
        );
      }, 0);
    });

    return {
      statistics,
      certificatedGroupedByFuelAndPriceAskAndMonth,
      chartRef,
      totalGOs,
      supportedGOs,
      unsupportedGOs,
      getFuelCodeName,
      calculateTotalSupported,
      calculateTotalUnsupported,
      calculateTotalByPrice,
      displayPriceRow,
    };
  },
});
</script>

<style scoped>
#AdminDashboard {
  flex-grow: 1;
  text-align: left;
  overflow-y: auto;
  max-width: 100%;
}

.table-container {
  border-radius: 1rem;
  border: 1px solid var(--phoenix-gray-200);
  overflow: hidden !important;
}

.table > tbody > tr > td:first-child {
  padding-left: 1rem;
}

#certificates-table {
  margin-bottom: 0;
}

.table-active {
  background-color: var(--phoenix-warning-bg-subtle);
}

.table-active td {
  background-color: #fff3cd;
  box-shadow: none;
}

.chart-container {
  position: relative;
  height: 400px;
}

.chart-title {
  text-align: center;
  margin-bottom: 1rem;
}

.chart-title h4 {
  font-size: 1.25rem;
  font-weight: bold;
}

.chart-title p {
  margin-bottom: 0.25rem;
}

.card {
  border: 1px solid var(--phoenix-gray-200);
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.card-text {
  margin-bottom: 0.5rem;
}

.table-summary {
  background-color: #f0f0f0; /* Light grey background for summary row */
}
</style>
