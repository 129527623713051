<!-- client/src/components/common/GeneratorCardComponent.vue -->
<template>
  <div class="card mb-2 generator-card">
    <div class="card-body">
      <div>
        <h2 class="accordion-header" :id="'heading' + generator.id">
          <a
            href="#"
            class="collapsed"
            type="accordion-button button"
            data-bs-toggle="collapse"
            :data-bs-target="'#collapse' + generator.id"
            aria-expanded="false"
            :aria-controls="'collapse' + generator.id"
          >
            <div class="card-title">
              {{ $t("generator.EIC") }}: {{ generator.device_eic }}
              <label
                v-if="is_admin"
                class="alert alert-subtle-warning text-center admin-extra-info"
              >
                {{ generator.id }}
              </label>
            </div>
          </a>
        </h2>
        <div class="card-subtitle mb-3">
          <span>
            <span :class="generatorStatusClass">
              {{ $t(`generator.status.${generatorStatusState}`) }}
            </span>
            <a
              :href="`/signup?step=2&generator_id=${generator.id}&company_id=${generator.company_id}`"
              class="link"
              v-if="generatorStatusState === GeneratorStatus.Unregistered"
            >
              ({{ $t("generator.continue_registration") }})
            </a>
            <a
              :href="generatorDocumentToSign"
              class="btn btn-link p-0"
              v-if="
                generatorStatusState === GeneratorStatus.PendingSignature &&
                generatorDocumentToSign
              "
              target="_blank"
            >
              <font-awesome-icon class="px-1" icon="signature" />
              ({{ $t("generator.sign_document") }})
            </a>
            <a
              v-if="is_admin"
              class="alert alert-subtle-warning text-center admin-extra-info"
              :href="`/signup?step=2&generator_id=${generator.id}&company_id=${generator.company_id}`"
            >
              {{ $t("common.edit") }}
            </a>
          </span>
        </div>
        <div
          :id="'collapse' + generator.id"
          class="accordion-collapse collapse"
          :aria-labelledby="'heading' + generator.id"
        >
          <div class="card-text">
            <div class="accordion border rounded p-2">
              <div class="accordion-item">
                <div class="accordion-body">
                  <div
                    v-for="propval in prop_values"
                    :key="propval.property"
                    class="generator-segment"
                  >
                    <div class="generator-property">
                      {{ $t(`generator.properties.${propval.property}`) }}:
                    </div>
                    <div class="generator-value">{{ propval.value }}</div>
                    <span
                      v-if="!propval.value"
                      class="badge rounded-pill bg-secondary text-white"
                    >
                      {{ $t("common.unspecified") }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h4 v-if="generatorDocuments.length > 0" class="mb-3 mt-3">
            {{ $t("generator.documents") }}
          </h4>
          <div
            v-for="document in generatorDocuments"
            :key="document.id"
            class="card mb-2"
          >
            <DocumentCardComponent
              :title="`${$t('generator.document_title', {
                eic: generator.device_eic,
              })}`"
              :document="document"
              :account_id="account_id"
              :is_admin="is_admin"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import type { Generator, Document } from "../../types";
import { computed } from "vue";
import DocumentCardComponent from "../common/DocumentCardComponent.vue";
import { useI18n } from "vue-i18n";

enum GeneratorStatus {
  Unregistered = 1,
  PendingSignature = 2,
  Registered = 3,
}

export default defineComponent({
  name: "GeneratorCardComponent",
  props: {
    generator: {
      type: Object as PropType<Generator>,
      required: true,
    },
    account_id: {
      type: Number,
      required: true,
    },
    generatorDocuments: {
      type: Array as PropType<Document[]>,
      required: true,
    },
    is_admin: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    DocumentCardComponent,
  },
  setup(props) {
    const { t } = useI18n();

    const prop_values = [
      {
        property: "device_name",
        value: props.generator.device_name,
      },
      {
        property: "solar_panel_model",
        value: props.generator.solar_panel_model,
      },
      {
        property: "device_address",
        value: props.generator.device_address,
      },
      {
        property: "device_power",
        value: computed(() => props.generator.device_power?.toString() + " kW"),
      },
      {
        property: "battery",
        value: computed(() =>
          props.generator.battery
            ? `${props.generator.battery.kw} kW, ${props.generator.battery.kwh} KWh`
            : t("generator.no_battery")
        ),
      },
      {
        property: "subsidies",
        value: computed(() => {
          if (props.generator.subsidies.length > 0) {
            return props.generator.subsidies
              .map((subsidy) => {
                let subsidyDetails = [
                  subsidy.provider,
                  subsidy.decision_date
                    ? t("common.date", { date: subsidy.decision_date })
                    : "",
                  subsidy.plan,
                  subsidy.amount ? `${subsidy.amount}€` : "",
                ]
                  .filter((detail) => detail)
                  .join(", ");
                return subsidyDetails;
              })
              .join("; ");
          }
          return t("common.none");
        }),
      },
    ];

    const generatorStatusState = computed((): GeneratorStatus => {
      if (
        props.generatorDocuments.some(
          (document: Document) => document.status !== "completed"
        )
      ) {
        return GeneratorStatus.PendingSignature;
      } else if (props.generator.has_registered) {
        return GeneratorStatus.Registered;
      } else {
        return GeneratorStatus.Unregistered;
      }
    });

    const generatorStatusClass = computed(() => {
      const statusClasses = {
        [GeneratorStatus.Unregistered]:
          "badge bg-danger-subtle text-danger-emphasis",
        [GeneratorStatus.PendingSignature]:
          "badge bg-danger-subtle text-warning-emphasis",
        [GeneratorStatus.Registered]:
          "badge bg-success-subtle text-success-emphasis",
      };
      return statusClasses[generatorStatusState.value];
    });

    const generatorDocumentToSign = computed(() => {
      const documentToSign = props.generatorDocuments.find(
        (document: Document) => document.status !== "completed"
      );
      if (documentToSign) {
        return documentToSign.document_signers.find(
          (signer) => signer.account_id === props.account_id
        )?.redirect_url;
      } else {
        return null;
      }
    });

    return {
      prop_values,
      generatorStatusState,
      generatorStatusClass,
      generatorDocumentToSign,
      GeneratorStatus,
    };
  },
});
</script>

<style scoped>
.generator-card {
  .card-title {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .generator-segment {
    .generator-property {
      font-weight: 600;
      color: var(--phoenix-secondary-text-emphasis);
    }
    .generator-value {
      margin-left: 1rem;
    }
    .badge {
      margin-left: 1rem;
    }
  }
  .accordion > .accordion-item:last-child {
    border-bottom: none;
  }
}
.admin-extra-info {
  padding: 0;
}
</style>
