import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "multiple-upload-checklist p-4 card"
}
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "mb-4 mb-sm-5" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadChecklistItemComponent = _resolveComponent("UploadChecklistItemComponent")!
  const _component_BasicInfoCardComponent = _resolveComponent("BasicInfoCardComponent")!

  return (_ctx.accountData.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("signup.multiple_upload.title")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("signup.multiple_upload.description")), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uploadChecklistItems, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.index
          }, [
            _createVNode(_component_UploadChecklistItemComponent, {
              uploadTitle: item.title,
              supportedFormats: item.supportedFormats,
              uploadUrl: item.uploadUrl,
              accountId: _ctx.accountData.id,
              fileUrl: item.fileUrl,
              queryParams: item.queryParams,
              onUploadComplete: ($event: any) => (_ctx.handleUploadComplete(item.index, $event)),
              onErrorOccured: _ctx.handleError
            }, null, 8, ["uploadTitle", "supportedFormats", "uploadUrl", "accountId", "fileUrl", "queryParams", "onUploadComplete", "onErrorOccured"]),
            _createVNode(_component_BasicInfoCardComponent, {
              buttonText: _ctx.$t('common.read_more_button'),
              titleText: item.infoTitle,
              class: "mb-6"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", {
                  class: "mt-2 mb-2",
                  innerHTML: item.infoContent
                }, null, 8, _hoisted_4)
              ]),
              _: 2
            }, 1032, ["buttonText", "titleText"])
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}