import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77ce6c85"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "generator-license-review card p-4 mb-3",
  novalidate: ""
}
const _hoisted_2 = { class: "mb-1 mb-sm-4" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "row mb-2 g-2" }
const _hoisted_5 = {
  for: "license-number",
  class: "col-sm-3 mt-sm-3 col-form-label"
}
const _hoisted_6 = { class: "col-sm-8 col-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("signup.generator_license_review.title")), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t("signup.generator_license_review.description")), 1),
    _createElementVNode("p", {
      innerHTML: _ctx.$t('signup.generator_license_review.directions')
    }, null, 8, _hoisted_3),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("generator.license_number")), 1),
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "license-number",
          class: _normalizeClass(["form-control", { 'is-invalid': _ctx.v$.license_number.$error }]),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localGenerator.license_number) = $event)),
          onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.v$.license_number.$touch && _ctx.v$.license_number.$touch(...args)))
        }, null, 34), [
          [_vModelText, _ctx.localGenerator.license_number]
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.license_number.$errors.filter(
            (_, index) => index < 1
          ), (error) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "invalid-feedback",
            key: error.$uid
          }, _toDisplayString(error.$message), 1))
        }), 128))
      ])
    ])
  ]))
}