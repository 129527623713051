import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card-body" }
const _hoisted_2 = { class: "card-title" }
const _hoisted_3 = {
  key: 0,
  class: "alert alert-subtle-warning text-center admin-extra-info"
}
const _hoisted_4 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 0,
  class: "alert alert-subtle-warning text-center admin-extra-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
      (_ctx.is_admin)
        ? (_openBlock(), _createElementBlock("label", _hoisted_3, [
            _createElementVNode("a", {
              target: "_blank",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.sendEleringEmail && _ctx.sendEleringEmail(...args)))
            }, _toDisplayString(_ctx.$t("document.resend_email")), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("p", {
      class: _normalizeClass([
        'badge',
        _ctx.document.status === 'completed'
          ? 'bg-success-subtle'
          : 'bg-warning-subtle',
        _ctx.document.status === 'completed'
          ? 'text-success-emphasis'
          : 'text-warning-emphasis',
      ])
    }, _toDisplayString(_ctx.document.status === "completed"
          ? _ctx.$t("document.signed")
          : _ctx.$t("document.awaiting_signature")), 3),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.document.document_signers, (signer) => {
      return (_openBlock(), _createElementBlock("div", {
        key: signer.id
      }, [
        (_ctx.document.status === 'completed')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("button", {
                class: "btn btn-link p-0",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.downloadDocument(_ctx.document.dokobit_token)))
              }, [
                _createVNode(_component_font_awesome_icon, {
                  class: "px-1",
                  icon: "download"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("document.download")), 1)
              ])
            ]))
          : (signer.account_id === _ctx.account_id && signer.redirect_url)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("a", {
                  href: signer.redirect_url,
                  class: "btn btn-link p-0",
                  target: "_blank"
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    class: "px-1",
                    icon: "signature"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("document.sign_document")), 1)
                ], 8, _hoisted_6),
                _createElementVNode("a", {
                  class: "btn btn-link p-0 text-warning-emphasis",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteDocument(_ctx.document.dokobit_token)))
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    class: "px-1",
                    icon: "trash"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("document.delete_document")), 1)
                ])
              ]))
            : _createCommentVNode("", true)
      ]))
    }), 128)),
    (_ctx.is_admin)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("a", {
            class: "btn btn-link p-0 text-warning-emphasis",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.adminDeleteDocument(_ctx.document.dokobit_token)))
          }, [
            _createVNode(_component_font_awesome_icon, {
              class: "px-1",
              icon: "trash"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("document.admin_delete_document")), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}