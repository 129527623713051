import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77007b10"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = { class: "d-flex justify-content-center mb-2 mt-5 align-items-end company-name" }
const _hoisted_3 = { class: "mx-2" }
const _hoisted_4 = { class: "fw-normal" }
const _hoisted_5 = {
  key: 0,
  class: "alert alert-subtle-warning text-center admin-extra-info"
}
const _hoisted_6 = { class: "d-flex justify-content-center mb-3" }
const _hoisted_7 = {
  key: 0,
  class: "fw-semibold mx-1"
}
const _hoisted_8 = {
  key: 1,
  class: "fw-semibold mx-1"
}
const _hoisted_9 = { class: "p-3 mb-4" }
const _hoisted_10 = { class: "row justify-content-between g-3" }
const _hoisted_11 = { class: "col-6 col-lg-4 text-center border-translucent border-end" }
const _hoisted_12 = { class: "d-inline-flex justify-content-center mb-3 align-items-end" }
const _hoisted_13 = { class: "mx-2" }
const _hoisted_14 = { class: "mb-2" }
const _hoisted_15 = { class: "col-6 col-lg-4 text-center border-translucent border-end-lg" }
const _hoisted_16 = { class: "d-inline-flex justify-content-center mb-3 align-items-end" }
const _hoisted_17 = { class: "mx-2" }
const _hoisted_18 = { class: "mb-2" }
const _hoisted_19 = ["disabled"]
const _hoisted_20 = { class: "col-12 col-lg-4 text-center d-grid" }
const _hoisted_21 = ["disabled"]
const _hoisted_22 = { key: 0 }
const _hoisted_23 = ["href"]
const _hoisted_24 = {
  key: 0,
  id: "dialog-card",
  class: "card p-3 mb-2"
}
const _hoisted_25 = { class: "card-body p-0" }
const _hoisted_26 = { class: "mt-2" }
const _hoisted_27 = { class: "card p-1 mb-2" }
const _hoisted_28 = { class: "card-body" }
const _hoisted_29 = { class: "mb-3" }
const _hoisted_30 = { class: "card p-1 mb-4" }
const _hoisted_31 = { class: "card-body" }
const _hoisted_32 = { class: "mb-3" }
const _hoisted_33 = { key: 1 }
const _hoisted_34 = { class: "mb-3" }
const _hoisted_35 = { key: 2 }
const _hoisted_36 = { class: "mb-3 mt-3" }
const _hoisted_37 = {
  key: 3,
  class: "card mb-2"
}
const _hoisted_38 = { class: "card-body" }
const _hoisted_39 = { class: "card-title" }
const _hoisted_40 = { class: "mt-2" }
const _hoisted_41 = ["href"]
const _hoisted_42 = { class: "badge bg-success-subtle text-success-emphasis" }
const _hoisted_43 = { key: 4 }
const _hoisted_44 = { class: "mb-3 mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_set_price_terms_component = _resolveComponent("set-price-terms-component")!
  const _component_request_invoice_component = _resolveComponent("request-invoice-component")!
  const _component_CertificateHistoryComponent = _resolveComponent("CertificateHistoryComponent")!
  const _component_InvoiceHistoryComponent = _resolveComponent("InvoiceHistoryComponent")!
  const _component_SalesTransactionsComponent = _resolveComponent("SalesTransactionsComponent")!
  const _component_TeamComponent = _resolveComponent("TeamComponent")!
  const _component_RegistryDevicesCardComponent = _resolveComponent("RegistryDevicesCardComponent")!
  const _component_GeneratorCardComponent = _resolveComponent("GeneratorCardComponent")!
  const _component_DocumentCardComponent = _resolveComponent("DocumentCardComponent")!
  const _component_loading_overlay_component = _resolveComponent("loading-overlay-component")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.company)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.company.name), 1),
            _createElementVNode("h3", _hoisted_4, "(" + _toDisplayString(_ctx.company.registry_code) + ")", 1),
            (_ctx.is_admin)
              ? (_openBlock(), _createElementBlock("label", _hoisted_5, _toDisplayString(_ctx.company.id), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.company.min_6_month_price === 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t("dashboard.companyData.automatic_sale_with_highest_offer")), 1))
              : _createCommentVNode("", true),
            (_ctx.company.min_6_month_price > 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t(
            "dashboard.companyData.fresh_production_sale_above_minimum_price",
            {
              price: _ctx.company.min_6_month_price,
            }
          )), 1))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              class: "btn btn-link p-0",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setDialogState('min-price')))
            }, " (" + _toDisplayString(_ctx.$t("dashboard.companyData.modify")) + ") ", 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.pendingPayout), 1),
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t("common.eur")), 1)
                ]),
                _createElementVNode("h4", _hoisted_14, _toDisplayString(_ctx.$t("dashboard.companyData.money_pending")), 1),
                _createElementVNode("button", {
                  class: "btn btn-phoenix-secondary",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setDialogState('sales-transactions')))
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    class: "px-1",
                    icon: "history"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("dashboard.companyData.transaction_history")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("h3", _hoisted_17, _toDisplayString(_ctx.certificatesCount), 1),
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t("common.mwh")), 1)
                ]),
                _createElementVNode("h4", _hoisted_18, _toDisplayString(_ctx.$t("dashboard.companyData.origin_certificates")), 1),
                _createElementVNode("button", {
                  class: "btn btn-phoenix-secondary",
                  disabled: _ctx.certificates.length == 0,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setDialogState('certificates-history')))
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    class: "px-1",
                    icon: "eye"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("dashboard.companyData.view_details")), 1)
                ], 8, _hoisted_19)
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("button", {
                  class: "btn btn-subtle-primary mb-2 btn-lg",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setDialogState('request-invoice'))),
                  disabled: _ctx.pendingInvoiceItems.length === 0
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    class: "px-1",
                    icon: "file-invoice-dollar"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("dashboard.companyData.submit_invoice")), 1)
                ], 8, _hoisted_21),
                _createElementVNode("button", {
                  class: "btn mb-2 btn-phoenix-secondary",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setDialogState('invoice-history')))
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    class: "px-1",
                    icon: "folder-open"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("dashboard.companyData.invoice_history")), 1)
                ]),
                _createElementVNode("button", {
                  class: "btn btn-phoenix-secondary",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setDialogState('team')))
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    class: "px-1",
                    icon: ['fas', 'people-group']
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("dashboard.companyData.team.title")), 1)
                ])
              ])
            ])
          ]),
          (_ctx.company.generators.length === 0 && _ctx.company.documents.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                _createElementVNode("a", {
                  href: `/signup?step=2&company_id=${_ctx.company.id}&addAuthorizationAgreement=true`,
                  class: "btn btn-subtle-primary btn-lg"
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    class: "px-1",
                    icon: "play"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("dashboard.companyData.continue_authorization_creation")), 1)
                ], 8, _hoisted_23)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_Transition, { name: "expand" }, {
            default: _withCtx(() => [
              (!!_ctx.dialogState)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    _createElementVNode("button", {
                      id: "dialog-close-button",
                      class: "btn p-1",
                      type: "button",
                      "aria-label": "Close",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.closeDialog()))
                    }, [
                      _createVNode(_component_font_awesome_icon, { icon: "times" })
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("div", _hoisted_26, [
                        (_ctx.dialogState === 'min-price')
                          ? (_openBlock(), _createBlock(_component_set_price_terms_component, {
                              key: 0,
                              initialPrice: _ctx.company.min_6_month_price,
                              onUpdateMinPrice: _ctx.updateMinPrice
                            }, null, 8, ["initialPrice", "onUpdateMinPrice"]))
                          : _createCommentVNode("", true),
                        (_ctx.dialogState === 'request-invoice')
                          ? (_openBlock(), _createBlock(_component_request_invoice_component, {
                              key: 1,
                              company: _ctx.company,
                              pendingInvoiceItems: _ctx.pendingInvoiceItems,
                              defaultInvoiceNumber: _ctx.defaultInvoiceNumber,
                              onInvoiceRequested: _ctx.handleInvoiceRequested
                            }, null, 8, ["company", "pendingInvoiceItems", "defaultInvoiceNumber", "onInvoiceRequested"]))
                          : _createCommentVNode("", true),
                        (_ctx.dialogState === 'certificates-history')
                          ? (_openBlock(), _createBlock(_component_CertificateHistoryComponent, {
                              key: 2,
                              certificates: _ctx.certificates
                            }, null, 8, ["certificates"]))
                          : _createCommentVNode("", true),
                        (_ctx.dialogState === 'invoice-history' && _ctx.company.id)
                          ? (_openBlock(), _createBlock(_component_InvoiceHistoryComponent, {
                              key: 3,
                              company_id: _ctx.company.id,
                              is_admin: _ctx.is_admin
                            }, null, 8, ["company_id", "is_admin"]))
                          : _createCommentVNode("", true),
                        (_ctx.dialogState === 'sales-transactions' && _ctx.company.id)
                          ? (_openBlock(), _createBlock(_component_SalesTransactionsComponent, {
                              key: 4,
                              company_id: _ctx.company.id,
                              nda_signed_link: _ctx.company.nda_signed_link
                            }, null, 8, ["company_id", "nda_signed_link"]))
                          : _createCommentVNode("", true),
                        (_ctx.dialogState === 'team' && _ctx.company.id)
                          ? (_openBlock(), _createBlock(_component_TeamComponent, {
                              key: 5,
                              company_id: _ctx.company.id,
                              is_admin: _ctx.is_admin,
                              company: _ctx.company
                            }, null, 8, ["company_id", "is_admin", "company"]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("h4", _hoisted_29, " 🙏 " + _toDisplayString(_ctx.$t("dashboard.companyData.thank_you_for_joining")), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("dashboard.companyData.thank_you_message")), 1),
              _createElementVNode("button", {
                class: "btn btn-subtle-primary me-1 mb-1",
                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.goToRegisterNewDevice && _ctx.goToRegisterNewDevice(...args)))
              }, [
                _createVNode(_component_font_awesome_icon, { icon: "plus" }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("dashboard.companyData.add_companies_or_devices_while_waiting")), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("h4", _hoisted_32, " 📢 " + _toDisplayString(_ctx.$t("dashboard.companyData.invite_a_friend")), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("dashboard.companyData.invite_a_friend_message")), 1),
              _createElementVNode("button", {
                onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.copyReferralLink && _ctx.copyReferralLink(...args))),
                class: "btn btn-subtle-primary"
              }, [
                _createVNode(_component_font_awesome_icon, { icon: "fa-copy" }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("dashboard.companyData.click_to_copy_link")), 1)
              ])
            ])
          ]),
          _createVNode(_component_RegistryDevicesCardComponent, {
            company_id: _ctx.company.id!,
            is_admin: _ctx.is_admin
          }, null, 8, ["company_id", "is_admin"]),
          (_ctx.company.generators.length !== 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                _createElementVNode("h4", _hoisted_34, _toDisplayString(_ctx.$t("dashboard.companyData.measurement_points")), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.company.generators, (generator) => {
                  return (_openBlock(), _createBlock(_component_GeneratorCardComponent, {
                    key: generator.id,
                    generator: generator,
                    account_id: _ctx.account_id,
                    title: 
          _ctx.$t('dashboard.companyData.device_contract', {
            deviceName: generator.name,
          })
        ,
                    generatorDocuments: _ctx.filterGeneratorDocuments(generator.id ?? 0),
                    is_admin: _ctx.is_admin
                  }, null, 8, ["generator", "account_id", "title", "generatorDocuments", "is_admin"]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.filterSolderaDocuments() && _ctx.filterSolderaDocuments().length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                _createElementVNode("h4", _hoisted_36, _toDisplayString(_ctx.$t("dashboard.companyData.documents_signed_with_soldera")), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterSolderaDocuments(), (document) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: document.id,
                    class: "card mb-2"
                  }, [
                    _createVNode(_component_DocumentCardComponent, {
                      title: `${_ctx.$t(
            'dashboard.companyData.contract_between_company_and_soldera',
            {
              companyName: _ctx.company.name,
            }
          )}`,
                      document: document,
                      account_id: _ctx.account_id,
                      is_admin: _ctx.is_admin
                    }, null, 8, ["title", "document", "account_id", "is_admin"])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.company.nda_signed_link)
            ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("h5", _hoisted_39, _toDisplayString(_ctx.$t("dashboard.client_nda_signed_link_title")), 1),
                  _createElementVNode("div", _hoisted_40, [
                    _createElementVNode("a", {
                      target: "_blank",
                      href: _ctx.company.nda_signed_link
                    }, _toDisplayString(_ctx.$t("dashboard.client_nda_signed_link", { name: _ctx.company.name })), 9, _hoisted_41)
                  ]),
                  _createElementVNode("p", _hoisted_42, _toDisplayString(_ctx.$t("document.signed")), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (
        _ctx.filterDocumentsNotOnGeneratorOrSolderaContracts() &&
        _ctx.filterDocumentsNotOnGeneratorOrSolderaContracts().length > 0
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                _createElementVNode("h4", _hoisted_44, _toDisplayString(_ctx.$t("dashboard.companyData.other_documents")), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterDocumentsNotOnGeneratorOrSolderaContracts(), (document) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: document.id,
                    class: "card mb-2"
                  }, [
                    _createVNode(_component_DocumentCardComponent, {
                      title: 
            _ctx.$t('dashboard.companyData.company_contract', {
              companyName: _ctx.company.name,
            })
          ,
                      document: document,
                      account_id: _ctx.account_id,
                      is_admin: _ctx.is_admin
                    }, null, 8, ["title", "document", "account_id", "is_admin"])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_loading_overlay_component, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}