import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-97d94b72"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "card mb-2 registryDevice-card" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = ["id"]
const _hoisted_6 = ["data-bs-target", "aria-controls"]
const _hoisted_7 = { class: "card-title" }
const _hoisted_8 = {
  key: 0,
  class: "alert alert-subtle-warning text-center admin-extra-info"
}
const _hoisted_9 = ["id", "aria-labelledby"]
const _hoisted_10 = { class: "card-text" }
const _hoisted_11 = { class: "accordion border rounded p-2" }
const _hoisted_12 = { class: "accordion-item" }
const _hoisted_13 = { class: "accordion-body" }
const _hoisted_14 = { class: "registryDevice-segment" }
const _hoisted_15 = { class: "registryDevice-property" }
const _hoisted_16 = { class: "registryDevice-value" }
const _hoisted_17 = { class: "registryDevice-segment" }
const _hoisted_18 = { class: "registryDevice-property" }
const _hoisted_19 = { class: "registryDevice-value" }
const _hoisted_20 = { class: "registryDevice-segment" }
const _hoisted_21 = { class: "registryDevice-property" }
const _hoisted_22 = { class: "registryDevice-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.registryDevices.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t("dashboard.companyData.production_settings")), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.registryDevices, (registryDevice) => {
          return (_openBlock(), _createElementBlock("div", {
            key: registryDevice.id
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _createElementVNode("h2", {
                    class: "accordion-header",
                    id: 'heading' + registryDevice.id
                  }, [
                    _createElementVNode("a", {
                      href: "#",
                      class: "collapsed",
                      type: "accordion-button button",
                      "data-bs-toggle": "collapse",
                      "data-bs-target": '#collapse' + registryDevice.id,
                      "aria-expanded": "false",
                      "aria-controls": 'collapse' + registryDevice.id
                    }, [
                      _createElementVNode("div", _hoisted_7, [
                        _createTextVNode(_toDisplayString(_ctx.$t("generator.EIC")) + ": " + _toDisplayString(registryDevice.device_eic) + " - " + _toDisplayString(_ctx.$t(
                      `registry_device.status.${registryDevice.registry_status}`
                    )) + " ", 1),
                        (_ctx.is_admin)
                          ? (_openBlock(), _createElementBlock("label", _hoisted_8, _toDisplayString(registryDevice.id), 1))
                          : _createCommentVNode("", true)
                      ])
                    ], 8, _hoisted_6)
                  ], 8, _hoisted_5),
                  _createElementVNode("div", {
                    id: 'collapse' + registryDevice.id,
                    class: "accordion-collapse collapse",
                    "aria-labelledby": 'heading' + registryDevice.id
                  }, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("registry_device.device_name")) + ": ", 1),
                              _createElementVNode("div", _hoisted_16, _toDisplayString(registryDevice.device_name), 1)
                            ]),
                            _createElementVNode("div", _hoisted_17, [
                              _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t("registry_device.device_power")) + ": ", 1),
                              _createElementVNode("div", _hoisted_19, _toDisplayString(registryDevice.device_power), 1)
                            ]),
                            _createElementVNode("div", _hoisted_20, [
                              _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("registry_device.supported_device")) + "? ", 1),
                              _createElementVNode("div", _hoisted_22, _toDisplayString(registryDevice.support
                              ? _ctx.$t("common.yes")
                              : _ctx.$t("common.no")), 1)
                            ])
                          ])
                        ])
                      ])
                    ])
                  ], 8, _hoisted_9)
                ])
              ])
            ])
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}