<template>
  <div
    class="invoice-display-component d-flex align-items-center justify-content-between py-2 border-bottom"
  >
    <span
      v-if="is_admin"
      class="invoice-number alert alert-subtle-warning text-center admin-extra-info"
      >{{ invoice.id }}</span
    >
    <span class="invoice-number">{{ invoice.invoice_number }}</span>
    <span class="invoice-status">{{ invoiceStatus }}</span>
    <span class="invoice-payout">{{ totalPayout }} €</span>
    <button
      v-if="is_admin && invoice.invoice_type === 'fee'"
      class="btn btn-outline-primary btn-sm alert alert-subtle-warning text-center admin-extra-info"
      @click="regenerateInvoice"
      :disabled="isLoading"
    >
      <template v-if="isLoading">
        <font-awesome-icon class="px-1" icon="spinner" spin />
      </template>
      <template v-else>
        <font-awesome-icon class="px-1" icon="recycle" />
      </template>
    </button>
    <button
      class="btn btn-outline-primary btn-sm"
      @click="downloadInvoice"
      :disabled="isLoading"
    >
      <template v-if="isLoading">
        <font-awesome-icon class="px-1" icon="spinner" spin />
      </template>
      <template v-else>
        <font-awesome-icon class="px-1" icon="download" />
      </template>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, inject, ref } from "vue";
import { Invoice } from "@/types";
import { formatMoney } from "@/composables/formatUtils";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { useI18n } from "vue-i18n";
import { calculateTotalAmountFromInvoiceItemsWithVAT } from "@/composables/money/moneyUtils";

export default defineComponent({
  name: "InvoiceDisplayComponent",
  props: {
    invoice: {
      type: Object as PropType<Invoice>,
      required: true,
    },
    is_admin: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const isLoading = ref(false);
    const invoiceItems = computed(() => {
      if (props.invoice.invoice_type === "fee") {
        return props.invoice.fee_items;
      } else {
        return props.invoice.expense_items;
      }
    });

    const totalPayout = computed(() => {
      return formatMoney(
        calculateTotalAmountFromInvoiceItemsWithVAT(invoiceItems.value)
      );
    });

    const invoiceStatus = computed(() => {
      if (props.invoice.paid_at) {
        return t("invoice.status.paid");
      } else if (props.invoice.canceled_at) {
        return t("invoice.status.canceled");
      } else if (props.invoice.requested_at) {
        return t("invoice.status.requested");
      } else {
        return t("invoice.status.drafting");
      }
    });

    const regenerateInvoice = async () => {
      if (confirm(t("invoice.regenerate_confirmation"))) {
        isLoading.value = true;
        try {
          const response = await goSolidApi.post(
            `/api/admin/fee_invoice/${props.invoice.id}/regenerate`
          );

          if (response.status === 200) {
            window.location.reload();
          } else {
            throw new Error(t("invoice.error_downloading"));
          }
        } catch (error: any) {
          globalErrorHandler(error.response.data);
        } finally {
          isLoading.value = false;
        }
      }
    };

    const downloadInvoice = async () => {
      isLoading.value = true;
      try {
        const response = await goSolidApi.get(
          `api/download-invoice/${props.invoice.id}`,
          { responseType: "blob" }
        );

        if (response.status === 200) {
          // Create a Blob from the response
          const fileBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          // Generate a URL for the Blob
          const fileURL = window.URL.createObjectURL(fileBlob);

          // Create a temporary anchor element and trigger a download
          const downloadLink = document.createElement("a");
          downloadLink.href = fileURL;
          // TODO name this file the invoice number in file system friendly way
          downloadLink.setAttribute(
            "download",
            `invoice_${props.invoice.id}.pdf`
          );
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          window.URL.revokeObjectURL(fileURL);
        } else {
          throw new Error(t("invoice.error_downloading"));
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      } finally {
        isLoading.value = false;
      }
    };

    return {
      totalPayout,
      invoiceStatus,
      downloadInvoice,
      isLoading,
      regenerateInvoice,
      t, // Add this to use t function in template
    };
  },
});
</script>

<style scoped></style>
