<!-- client/src/components/common/DocumentCardComponent.vue -->

<template>
  <div class="card-body">
    <h5 class="card-title">
      {{ title }}
      <label
        v-if="is_admin"
        class="alert alert-subtle-warning text-center admin-extra-info"
      >
        <a target="_blank" @click="sendEleringEmail">
          {{ $t("document.resend_email") }}
        </a>
      </label>
    </h5>
    <p
      :class="[
        'badge',
        document.status === 'completed'
          ? 'bg-success-subtle'
          : 'bg-warning-subtle',
        document.status === 'completed'
          ? 'text-success-emphasis'
          : 'text-warning-emphasis',
      ]"
    >
      {{
        document.status === "completed"
          ? $t("document.signed")
          : $t("document.awaiting_signature")
      }}
    </p>

    <div v-for="signer in document.document_signers" :key="signer.id">
      <div v-if="document.status === 'completed'" class="mt-2">
        <button
          class="btn btn-link p-0"
          @click="downloadDocument(document.dokobit_token)"
        >
          <font-awesome-icon class="px-1" icon="download" />
          {{ $t("document.download") }}
        </button>
      </div>
      <div v-else-if="signer.account_id === account_id && signer.redirect_url">
        <a :href="signer.redirect_url" class="btn btn-link p-0" target="_blank">
          <font-awesome-icon class="px-1" icon="signature" />
          {{ $t("document.sign_document") }}
        </a>
        <a
          class="btn btn-link p-0 text-warning-emphasis"
          @click="deleteDocument(document.dokobit_token)"
        >
          <font-awesome-icon class="px-1" icon="trash" />
          {{ $t("document.delete_document") }}
        </a>
      </div>
    </div>

    <div
      v-if="is_admin"
      class="alert alert-subtle-warning text-center admin-extra-info"
    >
      <a
        class="btn btn-link p-0 text-warning-emphasis"
        @click="adminDeleteDocument(document.dokobit_token)"
      >
        <font-awesome-icon class="px-1" icon="trash" />
        {{ $t("document.admin_delete_document") }}
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, PropType } from "vue";
import type { Document } from "../../types";
import { goSolidApi } from "../../composables/useGoSolidApi";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useI18n } from "vue-i18n";
import { useDownloadDocument } from "@/composables/useDownloadDocument";

export default defineComponent({
  name: "DocumentCardComponent",
  props: {
    title: {
      type: String,
      required: true,
    },
    document: {
      type: Object as PropType<Document>,
      required: true,
    },
    account_id: {
      type: Number,
      required: true,
    },
    is_admin: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    FontAwesomeIcon,
  },
  setup(props) {
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const { t } = useI18n();
    const { downloadDocument } = useDownloadDocument();

    const deleteDocument = async (token: string) => {
      try {
        // Ask for confirmation before deleting the document
        const isConfirmed = window.confirm(t("document.confirm_delete"));

        if (!isConfirmed) {
          return;
        }
        const response = await goSolidApi.delete(
          `/api/document-delete/${token}`
        );
        if (response.status === 200) {
          location.reload();
        } else {
          throw new Error("Error deleting the document");
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    };

    const adminDeleteDocument = async (token: string) => {
      try {
        // Ask for confirmation before deleting the document
        const isConfirmed = window.confirm(t("document.confirm_admin_delete"));

        if (!isConfirmed) {
          return;
        }
        const response = await goSolidApi.delete(
          `/api/admin/document/${token}`
        );
        if (response.status === 200) {
          location.reload();
        } else {
          throw new Error("Error deleting the document");
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    };

    const sendEleringEmail = async () => {
      try {
        const response = await goSolidApi.get(
          `/api/admin/send_elering_email/${props.document.id}`
        );
        if (response.status === 200) {
          alert("email sent");
        } else {
          throw new Error("Error sending elering email");
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    };

    return {
      downloadDocument,
      deleteDocument,
      sendEleringEmail,
      adminDeleteDocument,
    };
  },
});
</script>
<style scoped></style>
