import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "contact-review card p-4 mb-3" }
const _hoisted_2 = { class: "mb-1 mb-sm-4" }
const _hoisted_3 = {
  key: 0,
  class: "row mb-2 g-2 align-items-center"
}
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "col-sm-3 col-form-label" }
const _hoisted_6 = { class: "col-sm-6 col-10" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "col-sm-8 col-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("chooseCompany.select_company")), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accountData.companies, (company) => {
      return (_openBlock(), _createElementBlock("div", {
        key: company.id
      }, [
        (company.id)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("label", {
                for: company.id.toString(),
                class: "col-sm-3 col-form-label"
              }, _toDisplayString(company.name), 9, _hoisted_4),
              _createElementVNode("label", _hoisted_5, _toDisplayString(company.registry_code), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("button", {
                  class: "btn btn-secondary me-2",
                  onClick: ($event: any) => (_ctx.chooseCompany(company.id))
                }, _toDisplayString(_ctx.$t("chooseCompany.choose_company")), 9, _hoisted_7)
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("button", {
        class: "btn btn-secondary me-2",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.chooseCompany(undefined)))
      }, _toDisplayString(_ctx.$t("chooseCompany.create_new_company")), 1)
    ])
  ]))
}