import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "border rounded-2 bg-body" }
const _hoisted_2 = { class: "p-2 pb-0" }
const _hoisted_3 = { class: "d-flex flex-column flex-md-row align-items-center justify-content-between p-1 border-0" }
const _hoisted_4 = {
  key: 0,
  class: "m-0 my-1"
}
const _hoisted_5 = { class: "border rounded-3 my-2 p-0 box-shadow overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "title")
      ]),
      (!_ctx.showCollapse)
        ? (_openBlock(), _createElementBlock("hr", _hoisted_4))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "content")
        ])
      ], 512), [
        [_vShow, _ctx.showCollapse]
      ])
    ]),
    (_ctx.expandable)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "btn btn-text w-100 p-1 pt-0",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleCollapse()))
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: _ctx.showCollapse ? 'chevron-up' : 'chevron-down'
          }, null, 8, ["icon"]),
          _createTextVNode(" " + _toDisplayString(_ctx.buttonText), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}