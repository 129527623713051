<!-- client/src/components/signup/ChooseCompanyComponent.vue -->

<template>
  <div class="contact-review card p-4 mb-3">
    <h3 class="mb-1 mb-sm-4">{{ $t("chooseCompany.select_company") }}</h3>

    <!-- Account Data Form -->
    <div v-for="company in accountData.companies" :key="company.id">
      <div class="row mb-2 g-2 align-items-center" v-if="company.id">
        <label :for="company.id.toString()" class="col-sm-3 col-form-label">
          {{ company.name }}
        </label>
        <label class="col-sm-3 col-form-label">
          {{ company.registry_code }}
        </label>
        <div class="col-sm-6 col-10">
          <button
            class="btn btn-secondary me-2"
            @click="chooseCompany(company.id)"
          >
            {{ $t("chooseCompany.choose_company") }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-sm-8 col-10">
      <button class="btn btn-secondary me-2" @click="chooseCompany(undefined)">
        {{ $t("chooseCompany.create_new_company") }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, onMounted } from "vue";
import type { Account } from "@/types";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "ChooseCompanyComponent",
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
  },
  setup(props) {
    const { accountData } = toRefs(props);
    const route = useRoute();
    const router = useRouter();

    const chooseCompany = (id: number | undefined) => {
      const newQuery = {
        ...route.query,
        step: "3",
        company_id: id,
      };
      router.push({ name: "SignUp", query: newQuery });
    };

    onMounted(() => {
      if (accountData.value.companies.length === 0) {
        const newQuery = {
          ...route.query,
          step: "3",
        };
        router.push({ name: "SignUp", query: newQuery });
      }
    });

    return { chooseCompany };
  },
});
</script>

<style scoped></style>
