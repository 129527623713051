<!-- client/src/views/DashboardView.vue -->

<template>
  <div
    id="dashboard"
    :class="{ 'd-flex': true, 'sidebar-open': isSidebarOpen }"
  >
    <!-- Mobile header that displays selected company -->
    <nav id="dashboard-mobile-header" class="navbar navbar-dark d-md-none">
      <div class="container-fluid justify-content-between">
        <span class="mb-0 company-name">
          {{
            selectedCompanyOrFirst
              ? selectedCompanyOrFirst.name
              : $t("dashboard.select_company")
          }}
        </span>
        <button class="navbar-toggler" type="button" @click="toggleSidebar">
          <font-awesome-icon icon="caret-down" />
        </button>
      </div>
    </nav>
    <!-- Dashboard sidebar for switching companies -->
    <div id="dashboard-sidebar">
      <button
        type="button"
        class="btn-close btn-close-white"
        aria-label="Close"
        @click="toggleSidebar"
      ></button>
      <div class="nav-title">{{ $t("dashboard.companies") }}</div>
      <ul class="company-list">
        <li
          v-for="company in accountData.companies"
          :key="company.id"
          :class="{
            'nav-item': true,
            'is-selected-nav': selectedCompanyOrFirst?.id === company?.id,
          }"
        >
          <a class="nav-link" @click="selectCompany(company.id!)" role="button">
            {{ company.name }}
            <label
              v-if="accountData.admin_account_id"
              class="alert alert-subtle-warning text-center admin-extra-info"
            >
              {{ company.id }}
            </label>
          </a>
          <div v-if="getDocumentsReadyToSign(company).length > 0">
            <div
              v-for="signer in getDocumentsReadyToSign(company)[0]
                .document_signers"
              :key="signer.id"
              class="sidebar-call-to-action"
            >
              <a
                :href="signer.redirect_url"
                class="btn btn-subtle-primary btn-sm sidebar-call-to-action-button"
              >
                <font-awesome-icon class="px-1" icon="signature" />
                {{ $t("dashboard.sign_document") }}
              </a>
            </div>
          </div>
          <div v-else>
            <div
              v-for="generator in company.generators.filter(
                (generator) => !generator.has_registered
              )"
              :key="generator.id"
              class="sidebar-call-to-action"
            >
              <div class="sidebar-call-to-action-title">
                EIC: {{ generator.device_eic }}
              </div>
              <a
                :href="`/signup?step=2&generator_id=${generator.id}&company_id=${generator.company_id}`"
                class="btn btn-subtle-primary btn-sm sidebar-call-to-action-button"
              >
                <font-awesome-icon class="px-1" icon="play" />
                {{ $t("dashboard.continue_registration") }}
              </a>
            </div>
          </div>
        </li>
        <button class="btn btn-link" @click="goToRegisterNewDevice">
          <font-awesome-icon icon="plus" />
          {{ $t("dashboard.add_company_or_device") }}
        </button>
      </ul>
      <div
        v-if="accountData.admin_account_id"
        class="alert alert-subtle-warning text-center"
      >
        <div class="form-group">
          <label>{{ $t("dashboard.add_company_access") }}</label>
          <input
            type="number"
            v-model="newCompanyId"
            class="form-control"
            placeholder='$t("dashboard.company_id")'
          />
        </div>
        <button class="btn btn-primary" @click="confirmAddCompany">
          {{ $t("dashboard.add_company") }}
        </button>
      </div>
    </div>
    <div id="dashboard-wrapper" class="pl-sm-3">
      <div id="dashboard-content" class="flex-grow-1 pt-4">
        <company-data-component
          v-if="accountData && accountData.id && selectedCompanyOrFirst"
          v-model:company="selectedCompanyOrFirst"
          :account_id="accountData.id"
          :is_admin="!!accountData.admin_account_id"
          :key="selectedCompanyOrFirst.id"
        />
        <!-- AccountDataDebugComponent for debugging -->
        <account-data-debug-component :accountData="accountData" class="mb-4" />
        <!-- Admin only content -->
        <div
          v-if="accountData.admin_account_id && selectedCompanyOrFirst"
          class="alert alert-subtle-warning text-center"
        >
          <div class="form-group">
            <label>{{ $t("dashboard.internal_role") }}</label>
            <select v-model="accountData.internal_role" class="form-control">
              <option value="user">{{ $t("dashboard.user") }}</option>
              <option value="super_admin">
                {{ $t("dashboard.super_admin") }}
              </option>
            </select>
          </div>
          <!-- Company Fee Model Selection -->
          <div class="form-group">
            <label>{{ $t("dashboard.fee_model") }}</label>
            <div class="d-flex align-items-center">
              <select
                v-model="selectedFeeModel"
                class="form-control mr-2"
                @change="updateFeeModel"
              >
                <option value="standard">{{ $t("dashboard.standard") }}</option>
                <option value="special_free">
                  {{ $t("dashboard.special_free") }}
                </option>
                <option value="special_1eur_per_mw">
                  {{ $t("dashboard.special_1eur_per_mw") }}
                </option>
                <option value="custom_percentage">
                  {{ $t("dashboard.custom_percentage") }}
                </option>
              </select>
              <input
                v-if="selectedFeeModel === 'custom_percentage'"
                type="number"
                v-model.number="customPercentage"
                class="form-control ml-2"
                min="5"
                max="20"
                step="1"
                @input="updateCustomPercentage"
              />
              <span v-if="selectedFeeModel === 'custom_percentage'" class="ml-2"
                >%</span
              >
            </div>
            <small
              v-if="selectedFeeModel === 'custom_percentage'"
              class="form-text text-muted"
            >
              {{ $t("dashboard.custom_percentage_hint") }}
            </small>
          </div>
          <!-- Registry Excluded Devices -->
          <div class="form-group">
            <label>{{ $t("dashboard.registry_excluded_devices") }}</label>
            <input
              type="text"
              v-model="selectedCompanyOrFirst!.registry_excluded_devices"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label>{{ $t("dashboard.nda_signed_link") }}</label>
            <input
              type="text"
              v-model="selectedCompanyOrFirst!.nda_signed_link"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label>{{ $t("account.comment") }}</label>
            <input
              type="text"
              v-model="accountData.comment"
              class="form-control"
            />
          </div>
          <!-- Is Test Company Radio Buttons -->
          <div class="form-group">
            <label>{{ $t("dashboard.is_test_company") }}</label>
            <div>
              <input
                type="radio"
                id="testYes"
                value="true"
                v-model="selectedCompanyOrFirst!.is_test_company"
              />
              <label for="testYes">{{ $t("common.yes") }}</label>
              <input
                type="radio"
                id="testNo"
                value="false"
                v-model="selectedCompanyOrFirst!.is_test_company"
              />
              <label for="testNo">{{ $t("common.no") }}</label>
            </div>
          </div>
          <button class="btn btn-primary" @click="updateAccountData">
            {{ $t("dashboard.save_changes") }}
          </button>
          <!-- Display of All Tracking Information for the Account -->
          <div class="admin-trackings">
            <h3>{{ $t("dashboard.all_trackings") }}</h3>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>{{ $t("common.id") }}</th>
                  <th>{{ $t("dashboard.referrer_id") }}</th>
                  <th>{{ $t("dashboard.utm_source") }}</th>
                  <th>{{ $t("dashboard.utm_medium") }}</th>
                  <th>{{ $t("dashboard.utm_term") }}</th>
                  <th>{{ $t("dashboard.utm_content") }}</th>
                  <th>{{ $t("dashboard.utm_campaign") }}</th>
                  <th>{{ $t("dashboard.created_at") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="tracking in accountData.trackings"
                  :key="tracking.id"
                >
                  <td>{{ tracking.id }}</td>
                  <td>
                    <input
                      type="text"
                      v-model="tracking.referrer_id"
                      class="form-control"
                    />
                    <button
                      class="btn btn-primary"
                      @click="updateTracking(tracking)"
                    >
                      {{ $t("dashboard.save_changes") }}
                    </button>
                  </td>
                  <td>{{ tracking.utm_source }}</td>
                  <td>{{ tracking.utm_medium }}</td>
                  <td>{{ tracking.utm_term }}</td>
                  <td>{{ tracking.utm_content }}</td>
                  <td>{{ tracking.utm_campaign }}</td>
                  <td>{{ tracking.created_at }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <label for="companySelect">
            {{ $t("dashboard.admin_only_transfer_and_delete_company") }}
          </label>
          <select
            v-model="selectedTransferCompanyId"
            id="companySelect"
            class="form-control"
          >
            <option
              v-for="company in accountData.companies"
              :key="company.id"
              :value="company.id"
            >
              {{
                $t("dashboard.transfer_to", {
                  name: company.name,
                  id: company.id,
                })
              }}
            </option>
          </select>
          <button class="btn btn-primary mt-2" @click="confirmTransfer">
            {{ $t("dashboard.transfer") }}
          </button>
        </div>
        <!-- Admin only content end -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import type { Account, Document, ConversionTracking } from "@/types";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { fetchAccount } from "@/composables/account/fetchAccount";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import CompanyDataComponent from "@/components/dashboard/CompanyDataComponent.vue";
import AccountDataDebugComponent from "@/components/common/AccountDataDebugComponent.vue";
import { useCompanyFinder } from "@/composables/useCompanyFinder";
import type { Company } from "@/types";

export default defineComponent({
  name: "DashboardView",
  components: {
    FontAwesomeIcon,
    CompanyDataComponent,
    AccountDataDebugComponent,
  },
  setup() {
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const route = useRoute();
    const router = useRouter();
    const isSidebarOpen = ref(false);

    const afterFetchAccount = (accountData: Account) => {
      if (accountData.companies.length === 0 && !accountData.admin_account_id) {
        router.push("/signup");
      }
      checkStatusForAllDocuments();
    };
    const { accountData } = fetchAccount(afterFetchAccount);

    const toggleSidebar = () => {
      isSidebarOpen.value = !isSidebarOpen.value;
    };

    const selectCompany = (company_id: number) => {
      const newQuery = {
        ...route.query,
        company_id: company_id,
      };
      router.push({ name: "Dashboard", query: newQuery });
      toggleSidebar();
    };
    const { selectedCompanyOrFirst } = useCompanyFinder(accountData);

    const goToRegisterNewDevice = () => {
      router.push({ name: "SignUp" });
    };

    const checkStatusForAllDocuments = async () => {
      for (const company of accountData.companies) {
        for (const document of company.documents) {
          for (const signer of document.document_signers) {
            if (signer.account_id === accountData.id) {
              await checkStatus(document, document.dokobit_token);
            }
          }
        }
      }
    };

    const checkStatus = async (document: Document, token: string) => {
      try {
        const response = await goSolidApi.get(
          `/api/document-check-status?token=${token}`
        );
        if (response.status === 200 && response.data) {
          const data = response.data;
          if (data.status === "completed") {
            document.status = "completed";
            document.download_url = data.download_url; // Update download URL if available
          }
        } else {
          throw new Error("Error checking the document status");
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    };

    const newCompanyId = ref<number | null>(null);

    const confirmAddCompany = () => {
      if (newCompanyId.value) {
        const confirmed = window.confirm(
          `Are you sure you want to add company with ID ${newCompanyId.value}?`
        );
        if (confirmed) {
          addCompanyToAccount(newCompanyId.value);
        }
      }
    };

    const addCompanyToAccount = async (company_id: number) => {
      try {
        const response = await goSolidApi.post(
          `/api/admin/accounts/${accountData.id}/add-company/${company_id}`,
          {}
        );
        if (response.status === 200) {
          // Handle response
          console.log("Transfer successful", response);
          window.location.reload();
        } else {
          throw new Error("Error transferring assets");
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    };

    const selectedFeeModel = ref("standard");
    const customPercentage = ref(5);

    const parseFeeModel = (feeModel: string | null) => {
      if (!feeModel) {
        selectedFeeModel.value = "standard";
        customPercentage.value = 5;
        return;
      }
      const match = feeModel.match(/^special_(\d{1,2})pct$/);
      if (match) {
        selectedFeeModel.value = "custom_percentage";
        customPercentage.value = parseInt(match[1], 10);
      } else {
        selectedFeeModel.value = feeModel;
      }
    };

    watch(
      () => selectedCompanyOrFirst.value,
      (newCompany) => {
        if (newCompany) {
          parseFeeModel(newCompany.fee_model);
        }
      },
      { immediate: true }
    );

    const updateFeeModel = () => {
      if (selectedCompanyOrFirst.value) {
        if (selectedFeeModel.value === "custom_percentage") {
          selectedCompanyOrFirst.value.fee_model = `special_${customPercentage.value}pct`;
        } else {
          selectedCompanyOrFirst.value.fee_model = selectedFeeModel.value;
        }
      }
    };

    const updateCustomPercentage = () => {
      customPercentage.value = Math.min(
        99,
        Math.max(1, Math.round(customPercentage.value))
      );
      if (selectedCompanyOrFirst.value) {
        selectedCompanyOrFirst.value.fee_model = `special_${customPercentage.value}pct`;
      }
    };

    const updateTracking = async (tracking: ConversionTracking) => {
      const confirmed = window.confirm(
        `Are you sure you want to update tracking id ${tracking.id} to have referrer ${tracking.referrer_id}?`
      );
      if (confirmed) {
        try {
          const response = await goSolidApi.post(
            `/api/admin/tracking/${tracking.id}`,
            { referrer_id: tracking.referrer_id }
          );
          if (response.status === 200) {
            // Handle response
            window.location.reload();
          } else {
            throw new Error("Error updating tracking");
          }
        } catch (error: any) {
          globalErrorHandler(error.response.data);
        }
      }
    };

    const selectedTransferCompanyId = ref<number | null>(null);

    const confirmTransfer = () => {
      if (selectedTransferCompanyId.value) {
        const selectedTransferCompany = accountData.companies.find(
          (company: Company) => company.id === selectedTransferCompanyId.value
        );
        const confirmed = window.confirm(
          `Are you sure you want to transfer all assets from
          ${selectedCompanyOrFirst.value?.name}, ID ${selectedCompanyOrFirst.value?.id} to company
          ${selectedTransferCompany?.name}, ID: ${selectedTransferCompanyId.value}?`
        );
        if (confirmed) {
          transferAssets(selectedTransferCompanyId.value);
        }
      }
    };

    const transferAssets = async (company_id: number) => {
      try {
        console.log("transfering assets to company", company_id);
        const response = await goSolidApi.post(
          `/api/admin/company/${selectedCompanyOrFirst.value?.id}/transfer_assets_and_delete_company/${company_id}`,
          {}
        );
        if (response.status === 200) {
          // Handle response
          console.log("Transfer successful", response);
          window.location.reload();
        } else {
          throw new Error("Error transferring assets");
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    };

    const getDocumentsReadyToSign = (company: Company): Document[] => {
      return company.documents.filter(
        (document) => document.status !== "completed"
      );
    };

    const updateAccountData = async () => {
      if (
        typeof selectedCompanyOrFirst!.value!.registry_excluded_devices ===
        "string"
      ) {
        selectedCompanyOrFirst!.value!.registry_excluded_devices = (
          selectedCompanyOrFirst!.value!.registry_excluded_devices as string
        ).split(",");
      }
      try {
        const payload = {
          ...accountData,
          companies: accountData.companies.map((company: Company) => {
            if (company.id === selectedCompanyOrFirst.value?.id) {
              return { ...company, ...selectedCompanyOrFirst.value };
            }
            return company;
          }),
        };

        const response = await goSolidApi.post(
          `/api/admin/company/${selectedCompanyOrFirst.value?.id}`,
          {
            accountData: payload,
          }
        );
        Object.assign(accountData, response.data.accountData);
        alert("Account data updated successfully.");
      } catch (error: any) {
        globalErrorHandler(error.response.data);
        alert("Failed to update account data.");
      }
    };

    return {
      selectedTransferCompanyId,
      confirmTransfer,
      updateAccountData,
      accountData,
      selectedCompanyOrFirst,
      goToRegisterNewDevice,
      checkStatus,
      toggleSidebar,
      isSidebarOpen,
      selectCompany,
      getDocumentsReadyToSign,
      newCompanyId,
      confirmAddCompany,
      updateTracking,
      updateCustomPercentage,
      updateFeeModel,
      customPercentage,
      selectedFeeModel,
    };
  },
});
</script>

<style scoped lang="scss">
#dashboard {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex-grow: 1;
  width: 100%;
}

.admin-extra-info {
  padding: 0;
}

#dashboard-sidebar,
#dashboard-content {
  overflow-y: auto;
}

#dashboard-mobile-header {
  background-color: var(--phoenix-gray-800);
  color: var(--phoenix-white);
  padding: 0.5rem 1rem;
  .company-name {
    font-weight: bold;
    font-size: 1.2rem;
  }
}

@media (min-width: 769px) {
  .btn-close {
    display: none;
  }
}

@media (max-width: 768px) {
  #dashboard {
    flex-direction: column;
  }

  #dashboard.sidebar-open #dashboard-sidebar {
    transform: translateX(0);
  }

  #dashboard .navbar-toggler {
    color: white;
    border-width: 0;
  }

  #dashboard #dashboard-sidebar {
    max-width: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    height: 100vh;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
  }

  #dashboard #dashboard-wrapper {
    background-color: var(--phoenix-gray-800);
  }

  #dashboard.sidebar-open #dashboard-sidebar {
    transform: translateX(0);
  }

  .btn-close {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
  }
}

#dashboard-sidebar {
  background-color: var(--phoenix-navbar-vertical-bg-color);
  padding: 1rem;
  padding-top: 2rem;
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  flex-shrink: 0;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba(200, 200, 200, 1);
    background-color: var(--phoenix-gray-700);
    background-clip: padding-box;
    border-radius: 2px;
    border-right: 6px solid transparent;
    border-top: 10px solid transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: white;
    background-clip: padding-box;
    border-radius: 2px;
    border-right: 6px solid transparent;
    border-top: 10px solid transparent;

    //-webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
  }

  .nav-title {
    text-transform: uppercase;
    color: var(--phoenix-navbar-vertical-label-color);
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .company-list {
    list-style: none;
    padding: 0;
    margin: 0;
    .nav-item {
      font-weight: bold;
      color: var(--phoenix-navbar-vertical-link-color);
      padding: 0.5rem;
      padding-left: 1rem;
      margin-bottom: 1rem;
      &.is-selected-nav {
        color: var(--phoenix-navbar-vertical-link-active-color);
        background-color: var(--phoenix-navbar-vertical-link-hover-bg);
        border-radius: 8px;
      }
      .sidebar-call-to-action {
        margin-left: 1rem;
        .sidebar-call-to-action-button {
          margin-bottom: 0.5rem;
        }
        .sidebar-call-to-action-title {
          font-size: 0.8rem;
          font-weight: normal;
          margin-top: 0.25rem;
          color: var(--phoenix-gray-400);
        }
      }
    }
  }

  .btn-link {
    color: var(--phoenix-navbar-vertical-link-color);
    font-size: 1rem;
    padding: 0.5rem;
    padding-left: 1rem;
    margin-bottom: 1rem;
    &:hover {
      color: var(--phoenix-navbar-vertical-link-active-color);
    }
  }
}

#dashboard-content {
  flex-grow: 1;
  background-color: var(--phoenix-body-bg);
  border-radius: 1rem 1rem 0 0;
}

#dashboard-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;
  background-color: var(--phoenix-navbar-vertical-bg-color);
}
.container {
  text-align: left;
  max-width: 800px;
}
</style>
@/composables/account/fetchAccount
