<!-- client/src/components/signup/CountryPickerComponent.vue -->

<template>
  <div class="px-4 pb-1" v-if="!pickerExpanded">
    {{ $t("signup.choose_company_country") }}:
    {{ $t(localAccountData.country) }}
    <button @click="togglePicker" class="btn btn-link p-0">
      ({{ $t("common.edit") }})
    </button>
  </div>
  <div v-if="pickerExpanded" class="card p-4 mb-3">
    <h3 class="mb-1 mb-sm-2">
      {{ $t("signup.choose_company_country") }}
    </h3>
    <country-select
      class="form-control"
      v-model="localAccountData.country"
      :country="localAccountData.country"
      :disablePlaceholder="true"
      :whiteList="SUPPORTED_COUNTRIES"
      @change="handleUpdateCountry"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import type { Account } from "@/types";
import { useRoute, useRouter } from "vue-router";
import { useSelectedCountry } from "@/composables/useSelectedCountry";
import { useI18n } from "vue-i18n";
import { SUPPORTED_COUNTRIES } from "@/constants";

export default defineComponent({
  name: "CountryPickerComponent",
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
    handleUpdateAccountData: {
      type: Function as unknown as () => (updatedAccountData: Account) => void,
      required: true,
    },
  },
  setup(props) {
    const { selectedCountry } = useSelectedCountry(props.accountData);
    const localAccountData = reactive({ country: selectedCountry.value });
    const route = useRoute();
    const router = useRouter();

    // Picker is expanded if locale is "en" (default) and collapsed if locale is "et" or "lv"
    const { locale } = useI18n();
    const pickerExpanded = ref(locale.value == "en" ? true : false);

    const togglePicker = () => {
      pickerExpanded.value = !pickerExpanded.value;
    };

    const handleUpdateCountry = async () => {
      const newQuery = {
        ...route.query,
        addAuthorizationAgreement: undefined,
        company_id: undefined,
        generator_id: undefined,
        step: "1",
        country: localAccountData.country,
      };
      await router.push({ name: "SignUp", query: newQuery });
      // Reload the page, because we don't want to keep the old data from other countries that is unsaved
      // As an example - going to LV will create empty company with LV country. Then switching to EE will keep the LV company data
      // and if you go to add authorization in EE, you will then create LV company instead in EE flow.
      router.go(0);
    };

    return {
      localAccountData,
      handleUpdateCountry,
      pickerExpanded,
      togglePicker,
      SUPPORTED_COUNTRIES,
    };
  },
});
</script>

<style scoped></style>
