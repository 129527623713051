import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "invoice-history-component" }
const _hoisted_2 = { class: "fs-7 fw-bold mb-3" }
const _hoisted_3 = {
  key: 0,
  class: "text-center card p-2 bg-secondary-subtle"
}
const _hoisted_4 = { class: "fs-8 m-1 fw-bold" }
const _hoisted_5 = { class: "fs-8 m-1" }
const _hoisted_6 = { class: "d-flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InvoiceDisplayComponent = _resolveComponent("InvoiceDisplayComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t("invoiceHistory.title")), 1),
    (_ctx.invoices.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("invoiceHistory.noInvoices")), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("invoiceHistory.notificationInfo")), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoices, (invoice) => {
        return (_openBlock(), _createBlock(_component_InvoiceDisplayComponent, {
          key: invoice.id,
          invoice: invoice,
          is_admin: _ctx.is_admin
        }, null, 8, ["invoice", "is_admin"]))
      }), 128))
    ])
  ]))
}