<template>
  <div v-if="company" class="container">
    <div
      class="d-flex justify-content-center mb-2 mt-5 align-items-end company-name"
    >
      <h2 class="mx-2">{{ company.name }}</h2>
      <h3 class="fw-normal">({{ company.registry_code }})</h3>
      <label
        v-if="is_admin"
        class="alert alert-subtle-warning text-center admin-extra-info"
      >
        {{ company.id }}
      </label>
    </div>
    <div class="d-flex justify-content-center mb-3">
      <span class="fw-semibold mx-1" v-if="company.min_6_month_price === 0">
        {{ $t("dashboard.companyData.automatic_sale_with_highest_offer") }}
      </span>
      <span class="fw-semibold mx-1" v-if="company.min_6_month_price > 0">
        {{
          $t(
            "dashboard.companyData.fresh_production_sale_above_minimum_price",
            {
              price: company.min_6_month_price,
            }
          )
        }}
      </span>
      <button class="btn btn-link p-0" @click="setDialogState('min-price')">
        ({{ $t("dashboard.companyData.modify") }})
      </button>
    </div>
    <div class="p-3 mb-4">
      <div class="row justify-content-between g-3">
        <div class="col-6 col-lg-4 text-center border-translucent border-end">
          <div
            class="d-inline-flex justify-content-center mb-3 align-items-end"
          >
            <h3 class="mx-2">{{ pendingPayout }}</h3>
            <h3>{{ $t("common.eur") }}</h3>
          </div>
          <h4 class="mb-2">{{ $t("dashboard.companyData.money_pending") }}</h4>
          <button
            class="btn btn-phoenix-secondary"
            @click="setDialogState('sales-transactions')"
          >
            <font-awesome-icon class="px-1" icon="history" />
            {{ $t("dashboard.companyData.transaction_history") }}
          </button>
        </div>
        <div
          class="col-6 col-lg-4 text-center border-translucent border-end-lg"
        >
          <div
            class="d-inline-flex justify-content-center mb-3 align-items-end"
          >
            <h3 class="mx-2">{{ certificatesCount }}</h3>
            <h3>{{ $t("common.mwh") }}</h3>
          </div>
          <h4 class="mb-2">
            {{ $t("dashboard.companyData.origin_certificates") }}
          </h4>
          <button
            class="btn btn-phoenix-secondary"
            :disabled="certificates.length == 0"
            @click="setDialogState('certificates-history')"
          >
            <font-awesome-icon class="px-1" icon="eye" />
            {{ $t("dashboard.companyData.view_details") }}
          </button>
        </div>
        <div class="col-12 col-lg-4 text-center d-grid">
          <button
            class="btn btn-subtle-primary mb-2 btn-lg"
            @click="setDialogState('request-invoice')"
            :disabled="pendingInvoiceItems.length === 0"
          >
            <font-awesome-icon class="px-1" icon="file-invoice-dollar" />
            {{ $t("dashboard.companyData.submit_invoice") }}
          </button>
          <button
            class="btn mb-2 btn-phoenix-secondary"
            @click="setDialogState('invoice-history')"
          >
            <font-awesome-icon class="px-1" icon="folder-open" />
            {{ $t("dashboard.companyData.invoice_history") }}
          </button>
          <button
            class="btn btn-phoenix-secondary"
            @click="setDialogState('team')"
          >
            <font-awesome-icon class="px-1" :icon="['fas', 'people-group']" />
            {{ $t("dashboard.companyData.team.title") }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="company.generators.length === 0 && company.documents.length === 0"
    >
      <a
        :href="`/signup?step=2&company_id=${company.id}&addAuthorizationAgreement=true`"
        class="btn btn-subtle-primary btn-lg"
      >
        <font-awesome-icon class="px-1" icon="play" />
        {{ $t("dashboard.companyData.continue_authorization_creation") }}
      </a>
    </div>
    <Transition name="expand">
      <!-- Special card for dialog components-->
      <div id="dialog-card" class="card p-3 mb-2" v-if="!!dialogState">
        <button
          id="dialog-close-button"
          class="btn p-1"
          type="button"
          aria-label="Close"
          @click="closeDialog()"
        >
          <font-awesome-icon icon="times" />
        </button>
        <div class="card-body p-0">
          <div class="mt-2">
            <set-price-terms-component
              v-if="dialogState === 'min-price'"
              :initialPrice="company.min_6_month_price"
              @update-min-price="updateMinPrice"
            />
            <request-invoice-component
              v-if="dialogState === 'request-invoice'"
              :company="company"
              :pendingInvoiceItems="pendingInvoiceItems"
              :defaultInvoiceNumber="defaultInvoiceNumber"
              @invoice-requested="handleInvoiceRequested"
            />
            <CertificateHistoryComponent
              v-if="dialogState === 'certificates-history'"
              :certificates="certificates"
            />
            <InvoiceHistoryComponent
              v-if="dialogState === 'invoice-history' && company.id"
              :company_id="company.id"
              :is_admin="is_admin"
            />
            <SalesTransactionsComponent
              v-if="dialogState === 'sales-transactions' && company.id"
              :company_id="company.id"
              :nda_signed_link="company.nda_signed_link"
            />
            <TeamComponent
              v-if="dialogState === 'team' && company.id"
              :company_id="company.id"
              :is_admin="is_admin"
              :company="company"
            />
          </div>
        </div>
      </div>
    </Transition>
    <div class="card p-1 mb-2">
      <div class="card-body">
        <h4 class="mb-3">
          🙏 {{ $t("dashboard.companyData.thank_you_for_joining") }}
        </h4>
        <p>{{ $t("dashboard.companyData.thank_you_message") }}</p>
        <button
          class="btn btn-subtle-primary me-1 mb-1"
          @click="goToRegisterNewDevice"
        >
          <font-awesome-icon icon="plus" />
          {{
            $t("dashboard.companyData.add_companies_or_devices_while_waiting")
          }}
        </button>
      </div>
    </div>
    <div class="card p-1 mb-4">
      <div class="card-body">
        <h4 class="mb-3">
          📢 {{ $t("dashboard.companyData.invite_a_friend") }}
        </h4>
        <p>{{ $t("dashboard.companyData.invite_a_friend_message") }}</p>
        <button @click="copyReferralLink" class="btn btn-subtle-primary">
          <font-awesome-icon icon="fa-copy" />
          {{ $t("dashboard.companyData.click_to_copy_link") }}
        </button>
      </div>
    </div>
    <RegistryDevicesCardComponent
      :company_id="company.id!"
      :is_admin="is_admin"
    ></RegistryDevicesCardComponent>
    <div v-if="company.generators.length !== 0">
      <h4 class="mb-3">{{ $t("dashboard.companyData.measurement_points") }}</h4>
      <GeneratorCardComponent
        v-for="generator in company.generators"
        :key="generator.id"
        :generator="generator"
        :account_id="account_id"
        :title="
          $t('dashboard.companyData.device_contract', {
            deviceName: generator.name,
          })
        "
        :generatorDocuments="filterGeneratorDocuments(generator.id ?? 0)"
        :is_admin="is_admin"
      ></GeneratorCardComponent>
    </div>
    <div v-if="filterSolderaDocuments() && filterSolderaDocuments().length > 0">
      <h4 class="mb-3 mt-3">
        {{ $t("dashboard.companyData.documents_signed_with_soldera") }}
      </h4>
      <div
        v-for="document in filterSolderaDocuments()"
        :key="document.id"
        class="card mb-2"
      >
        <DocumentCardComponent
          :title="`${$t(
            'dashboard.companyData.contract_between_company_and_soldera',
            {
              companyName: company.name,
            }
          )}`"
          :document="document"
          :account_id="account_id"
          :is_admin="is_admin"
        ></DocumentCardComponent>
      </div>
    </div>
    <div v-if="company.nda_signed_link" class="card mb-2">
      <div class="card-body">
        <h5 class="card-title">
          {{ $t("dashboard.client_nda_signed_link_title") }}
        </h5>
        <div class="mt-2">
          <a target="_blank" :href="company.nda_signed_link">
            {{ $t("dashboard.client_nda_signed_link", { name: company.name }) }}
          </a>
        </div>
        <p class="badge bg-success-subtle text-success-emphasis">
          {{ $t("document.signed") }}
        </p>
      </div>
    </div>
    <div
      v-if="
        filterDocumentsNotOnGeneratorOrSolderaContracts() &&
        filterDocumentsNotOnGeneratorOrSolderaContracts().length > 0
      "
    >
      <h4 class="mb-3 mt-3">
        {{ $t("dashboard.companyData.other_documents") }}
      </h4>
      <div
        v-for="document in filterDocumentsNotOnGeneratorOrSolderaContracts()"
        :key="document.id"
        class="card mb-2"
      >
        <DocumentCardComponent
          :title="
            $t('dashboard.companyData.company_contract', {
              companyName: company.name,
            })
          "
          :document="document"
          :account_id="account_id"
          :is_admin="is_admin"
        ></DocumentCardComponent>
      </div>
    </div>
  </div>
  <loading-overlay-component v-if="isLoading"></loading-overlay-component>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  ref,
  inject,
  onMounted,
  computed,
} from "vue";
import type { Certificate, Company, Document, InvoiceItem } from "../../types";
import GeneratorCardComponent from "../common/GeneratorCardComponent.vue";
import RegistryDevicesCardComponent from "../common/RegistryDevicesCardComponent.vue";
import DocumentCardComponent from "../common/DocumentCardComponent.vue";
import LoadingOverlayComponent from "../common/LoadingOverlayComponent.vue";
import SetPriceTermsComponent from "./SetPriceTermsComponent.vue";
import RequestInvoiceComponent from "./RequestInvoiceComponent.vue";
import CertificateHistoryComponent from "./CertificateHistoryComponent.vue";
import InvoiceHistoryComponent from "./InvoiceHistoryComponent.vue";
import SalesTransactionsComponent from "./SalesTransactionsComponent.vue";
import TeamComponent from "./TeamComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useRouter } from "vue-router";
import { goSolidApi } from "@/composables/useGoSolidApi";
import { formatMoney } from "@/composables/formatUtils";
import { useI18n } from "vue-i18n";
import { calculateTotalAmountFromInvoiceItemsWithVAT } from "@/composables/money/moneyUtils";

export default defineComponent({
  props: {
    company: {
      type: Object as PropType<Company>,
      required: true,
    },
    account_id: {
      type: Number,
      required: true,
    },
    is_admin: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    FontAwesomeIcon,
    GeneratorCardComponent,
    DocumentCardComponent,
    SetPriceTermsComponent,
    LoadingOverlayComponent,
    RequestInvoiceComponent,
    RegistryDevicesCardComponent,
    CertificateHistoryComponent,
    InvoiceHistoryComponent,
    SalesTransactionsComponent,
    TeamComponent,
  },
  emits: ["update:company"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const handleSuccess = inject("globalSuccessHandler") as (
      message: string,
      title: string
    ) => void;
    const router = useRouter();
    const dialogState = ref("");
    const isLoading = ref(false);
    const certificates = ref<[Certificate] | []>([]);
    const certificatesCount = ref(0);
    const pendingInvoiceItems = ref<InvoiceItem[]>([]);
    const defaultInvoiceNumber = ref("");
    const pendingPayout = computed(() => {
      if (pendingInvoiceItems.value.length === 0) {
        return "-";
      }
      const payout = calculateTotalAmountFromInvoiceItemsWithVAT(
        pendingInvoiceItems.value
      );
      return formatMoney(payout);
    });

    const setDialogState = (state: string) => {
      dialogState.value = state;
    };

    const closeDialog = () => {
      dialogState.value = "";
    };

    const filterGeneratorDocuments = (generatorId: number) => {
      return props.company.documents.filter(
        (doc: Document) => doc.generator_id === generatorId
      );
    };
    const filterSolderaDocuments = () => {
      return props.company.documents.filter(
        (doc: Document) => doc.has_soldera_contract
      );
    };
    const filterDocumentsNotOnGeneratorOrSolderaContracts = () => {
      return props.company.documents.filter(
        (doc: Document) => !doc.generator_id && !doc.has_soldera_contract
      );
    };

    const goToRegisterNewDevice = () => {
      router.push({ name: "SignUp" });
    };
    const copyReferralLink = () => {
      const referralLink = `https://www.soldera.org/?referrer_id=${props.company.id}&utm_source=referral1&utm_medium=dashboard&utm_campaign=referral1`;

      // Attempt to use the Clipboard API
      if (navigator.clipboard && document.hasFocus()) {
        navigator.clipboard
          .writeText(referralLink)
          .then(() => {
            alert(t("dashboard.companyData.link_copied"));
          })
          .catch((_err) => {
            copyToFallback(referralLink); // Attempt fallback method
          });
      } else {
        copyToFallback(referralLink); // Use fallback directly if Clipboard API is not available
      }
    };

    async function updateMinPrice(newPrice: number) {
      isLoading.value = true;
      try {
        const response = await goSolidApi.post("api/update-min-price", {
          company_id: props.company.id,
          min_6_month_price: newPrice,
        });
        if (response.status === 200) {
          emitCompanyUpdate({
            ...props.company,
            min_6_month_price: newPrice,
          });
          closeDialog();
          handleSuccess(
            t("dashboard.companyData.update_min_price_success"),
            t("dashboard.companyData.new_min_price_effective_immediately")
          );
        } else {
          throw new Error(t("dashboard.companyData.update_min_price_failed"));
        }
      } catch (error) {
        globalErrorHandler(error);
      } finally {
        isLoading.value = false;
      }
    }

    async function handleInvoiceRequested() {
      handleSuccess(
        t("dashboard.companyData.invoice_submission_success"),
        t("dashboard.companyData.invoice_paid_within_7_days")
      );
      pendingInvoiceItems.value = [];
      closeDialog();
    }

    async function getPendingInvoiceItems() {
      try {
        const response = await goSolidApi.get(
          `/api/company/${props.company.id}/pending-invoice-items`
        );
        if (response.status === 200) {
          pendingInvoiceItems.value = response.data.invoice_items;
          defaultInvoiceNumber.value = response.data.default_invoice_number;
        } else {
          throw new Error(
            t("dashboard.companyData.fetch_pending_invoice_items_failed")
          );
        }
      } catch (error) {
        globalErrorHandler(error);
      }
    }

    function emitCompanyUpdate(newCompany: Company) {
      emit("update:company", newCompany);
    }

    // Fallback method using execCommand
    function copyToFallback(textToCopy: string) {
      const textarea = document.createElement("textarea");
      document.body.appendChild(textarea);
      textarea.value = textToCopy;
      textarea.select();
      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
        alert(t("dashboard.companyData.link_copied_fallback")); // Provide feedback
      } catch (err) {
        console.error("Fallback: Unable to copy", err);
        alert(t("dashboard.companyData.link_copy_failed")); // Feedback for total failure
      }
      document.body.removeChild(textarea);
    }

    async function fetchCertificates() {
      isLoading.value = true;
      try {
        const response = await goSolidApi.get(
          `/api/company/certificates/${props.company.id}`
        );
        if (response.status === 200) {
          certificates.value = response.data.certificates;
          certificatesCount.value = response.data.count;
        } else {
          throw new Error(t("dashboard.companyData.fetch_certificates_failed"));
        }
      } catch (error) {
        globalErrorHandler(error);
      } finally {
        isLoading.value = false;
      }
    }
    onMounted(() => {
      fetchCertificates();
      getPendingInvoiceItems();
    });

    return {
      filterGeneratorDocuments,
      filterSolderaDocuments,
      filterDocumentsNotOnGeneratorOrSolderaContracts,
      goToRegisterNewDevice,
      copyReferralLink,
      updateMinPrice,
      pendingPayout,
      defaultInvoiceNumber,
      pendingInvoiceItems,
      dialogState,
      setDialogState,
      closeDialog,
      isLoading,
      certificates,
      certificatesCount,
      handleInvoiceRequested,
    };
  },
});
</script>

<style scoped>
.container {
  text-align: left;
  max-width: 800px;
}
.admin-extra-info {
  padding: 0;
}

#dialog-close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 1000;
  background-color: transparent;
  border: none;
  font-size: 1.3rem;
  color: var(--bs-dark);
}

@media (max-width: 768px) {
  .company-name {
    display: none !important;
  }
}

/* Transition Styles */
.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.4s ease-in-out, opacity 0.5s ease; /* Adjust timing to suit */
}

.expand-enter-from,
.expand-leave-to {
  max-height: 0; /* Start from 0 height */
  opacity: 0;
  overflow: hidden;
}

.expand-enter-to,
.expand-leave-from {
  max-height: 500px; /* Adjust to the maximum height you expect your card to have */
  opacity: 1;
  overflow: auto;
}
</style>
