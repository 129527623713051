<!-- client/src/components/signup/lv/ContactReviewConfigurationComponent.vue -->

<template>
  <ContactReviewDisplayComponent
    :accountData="accountData"
    :companyIndex="companyIndex"
    :editableAccountFields="accountFields"
    :editableCompanyFields="companyFields"
    :shouldShowCompanyFields="shouldShowCompanyFields"
    @updateAccountData="$emit('updateAccountData', $event)"
    @handleUpdateCompany="$emit('handleUpdateCompany', $event)"
  />
</template>

<script lang="ts">
import { defineComponent, computed, watch } from "vue";
import type { Account, ContactFieldType } from "@/types";
import { useValidators } from "@/composables/useValidators";
import { useCompanyFinder } from "@/composables/useCompanyFinder";
import { useI18n } from "vue-i18n";
import ContactReviewDisplayComponent from "@/components/signup/ContactReviewDisplayComponent.vue";

export default defineComponent({
  name: "ContactReviewConfigurationComponent",
  components: {
    ContactReviewDisplayComponent,
  },
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
    companyIndex: {
      type: Number,
      required: false,
    },
  },
  emits: ["updateAccountData", "handleUpdateCompany"],
  setup(props) {
    const { t } = useI18n();
    const {
      required,
      latvianIDorLatvianRegistryCode,
      latvianIDorLatvianVATNumber,
      latvianYourPersonalIDifID,
      isValidLatvianPersonalId,
      personalName,
      phoneNumber,
    } = useValidators(t);

    const { selectedCompany } = useCompanyFinder(props.accountData);
    const company = selectedCompany.value;

    const accountFields: ContactFieldType[] = [
      {
        label: t("account.name"),
        id: "account-name",
        model: "name",
        type: "text",
        editable: true,
        validators: { required, personalName },
      },
      {
        label: t("account.phone_number"),
        id: "account-phone-number",
        model: "phone_number",
        type: "tel",
        editable: true,
        validators: { required, phoneNumber },
      },
    ];

    const companyFields: ContactFieldType[] = [
      {
        label: t("company.name"),
        id: "company-name",
        model: "name",
        type: "text",
        editable: true,
        validators: { required },
      },
      {
        label: t("company.registry_code"),
        id: "company-registry-code",
        model: "registry_code",
        type: "text",
        editable: true,
        validators: [required, latvianIDorLatvianRegistryCode],
      },
      {
        label: t("company.address"),
        id: "company-address",
        model: "address",
        type: "text",
        editable: true,
        validators: { required },
      },
    ];

    // Deprecated - we should remove this eventually, decided it's not good to hide fields
    const shouldShowCompanyFields = computed(() => {
      return true;
    });

    return {
      accountFields,
      companyFields,
      shouldShowCompanyFields,
      company,
    };
  },
});
</script>

<style scoped></style>
