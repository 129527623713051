import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "invoice-history-component" }
const _hoisted_2 = { class: "fs-7 fw-bold mb-3" }
const _hoisted_3 = {
  href: "https://www.soldera.org/article/mis-on-paritolutunnistuste-hind-siin-on-moned-avalikud-hinnasignaalid",
  target: "_blank"
}
const _hoisted_4 = {
  key: 0,
  class: "text-center card p-2 bg-secondary-subtle"
}
const _hoisted_5 = { class: "fs-8 m-1 fw-bold" }
const _hoisted_6 = { class: "fs-8 m-1" }
const _hoisted_7 = { class: "d-flex-row border rounded-3 overflow-hidden border-gray-200 fs-9" }
const _hoisted_8 = { class: "row bg-secondary-subtle mb-2 fw-bold d-none d-lg-flex p-1 g-0 align-items-center" }
const _hoisted_9 = { class: "col-2 p-1" }
const _hoisted_10 = { class: "mb-0" }
const _hoisted_11 = { class: "col-2 p-1 text-end" }
const _hoisted_12 = { class: "mb-0" }
const _hoisted_13 = { class: "col-2 p-1 text-end" }
const _hoisted_14 = { class: "mb-0" }
const _hoisted_15 = { class: "col-2 p-1 text-end" }
const _hoisted_16 = { class: "mb-0" }
const _hoisted_17 = { class: "col-2 p-1 text-end" }
const _hoisted_18 = { class: "mb-0" }
const _hoisted_19 = { class: "col-2 p-1 text-end" }
const _hoisted_20 = { class: "mb-0" }
const _hoisted_21 = { class: "col-6 col-lg-2 px-1 py-1 py-lg-0" }
const _hoisted_22 = { class: "mb-0 d-lg-none" }
const _hoisted_23 = {
  key: 0,
  class: "p-0 fw-bold"
}
const _hoisted_24 = ["href"]
const _hoisted_25 = { class: "col-6 col-lg-2 px-1 py-1 py-lg-0 text-lg-end" }
const _hoisted_26 = { class: "mb-0 d-lg-none" }
const _hoisted_27 = { class: "p-0 fw-bold" }
const _hoisted_28 = { class: "col-6 col-lg-2 px-1 py-1 py-lg-0 text-lg-end" }
const _hoisted_29 = { class: "mb-0 d-lg-none" }
const _hoisted_30 = { class: "p-0" }
const _hoisted_31 = { class: "col-6 col-lg-2 px-1 py-1 py-lg-0 text-lg-end" }
const _hoisted_32 = { class: "mb-0 d-lg-none" }
const _hoisted_33 = { class: "p-0" }
const _hoisted_34 = { class: "col-6 col-lg-2 px-1 py-1 py-lg-0 text-lg-end" }
const _hoisted_35 = { class: "mb-0 d-lg-none" }
const _hoisted_36 = { class: "p-0" }
const _hoisted_37 = { class: "col-6 col-lg-2 px-1 py-1 py-lg-0 text-lg-end" }
const _hoisted_38 = { class: "mb-0 d-lg-none" }
const _hoisted_39 = { class: "p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t("dashboard.sales_transactions.title")), 1),
    _createElementVNode("p", null, [
      _createTextVNode(_toDisplayString(_ctx.$t("dashboard.sales_transactions.description")) + " ", 1),
      _createElementVNode("a", _hoisted_3, _toDisplayString(_ctx.$t("dashboard.sales_transactions.description_link")), 1)
    ]),
    (_ctx.sales_transactions.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("dashboard.sales_transactions.no_sales_transactions")), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("dashboard.sales_transactions.notificationInfo")), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t("dashboard.sales_transactions.date")), 1)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t("dashboard.sales_transactions.item")), 1)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t("dashboard.sales_transactions.final_unit_price")), 1)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t("dashboard.sales_transactions.offers_taken")), 1)
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t("dashboard.sales_transactions.offer_range")), 1)
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t("dashboard.sales_transactions.final_country")), 1)
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sales_transactions, (sales_transaction) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "row mb-2 p-4 p-lg-1 g-0",
          key: sales_transaction.id
        }, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.$t("dashboard.sales_transactions.date")), 1),
            (!_ctx.nda_signed_link || _ctx.nda_signed_link == '')
              ? (_openBlock(), _createElementBlock("p", _hoisted_23, _toDisplayString(_ctx.formatDateTime(sales_transaction.transaction_at)), 1))
              : (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  href: sales_transaction.transaction_link,
                  target: "_blank",
                  class: "p-0 fw-bold"
                }, _toDisplayString(_ctx.formatDateTime(sales_transaction.transaction_at)), 9, _hoisted_24))
          ]),
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.$t("dashboard.sales_transactions.item")), 1),
            _createElementVNode("p", _hoisted_27, _toDisplayString(sales_transaction.description), 1)
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.$t("dashboard.sales_transactions.final_unit_price")), 1),
            _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.formatMoney(sales_transaction.unit_price)) + " €", 1)
          ]),
          _createElementVNode("div", _hoisted_31, [
            _createElementVNode("p", _hoisted_32, _toDisplayString(_ctx.$t("dashboard.sales_transactions.offers_taken")), 1),
            _createElementVNode("p", _hoisted_33, _toDisplayString(sales_transaction.offers_taken), 1)
          ]),
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.$t("dashboard.sales_transactions.offer_range")), 1),
            _createElementVNode("p", _hoisted_36, _toDisplayString(_ctx.formatMoney(sales_transaction.min_price)) + " - " + _toDisplayString(_ctx.formatMoney(sales_transaction.unit_price)), 1)
          ]),
          _createElementVNode("div", _hoisted_37, [
            _createElementVNode("p", _hoisted_38, _toDisplayString(_ctx.$t("dashboard.sales_transactions.final_country")), 1),
            _createElementVNode("p", _hoisted_39, _toDisplayString(sales_transaction.counterparty_country), 1)
          ])
        ]))
      }), 128))
    ])
  ]))
}