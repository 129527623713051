import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9950f1da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "signup-view" }
const _hoisted_2 = {
  key: 0,
  class: "container py-5 pt-3 pt-xl-5"
}
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EESignupFlow = _resolveComponent("EESignupFlow")!
  const _component_LVSignupFlow = _resolveComponent("LVSignupFlow")!
  const _component_LTSignupFlow = _resolveComponent("LTSignupFlow")!
  const _component_EmptySignupFlow = _resolveComponent("EmptySignupFlow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isAccountDataReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isAccountDataReady)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.selectedCountry === 'EE')
                  ? (_openBlock(), _createBlock(_component_EESignupFlow, {
                      key: 0,
                      accountData: _ctx.accountData,
                      companyIndex: _ctx.companyIndex,
                      generatorIndex: _ctx.generatorIndex,
                      companyIdQueryString: _ctx.companyIdQueryString,
                      handleUpdateAccountData: _ctx.handleUpdateAccountData,
                      onHandleUpdateCompany: _ctx.handleUpdateCompany,
                      onHandleUpdateGenerator: _ctx.handleUpdateGenerator,
                      onCreateCompanyIfMissing: _ctx.createCompanyIfMissing
                    }, null, 8, ["accountData", "companyIndex", "generatorIndex", "companyIdQueryString", "handleUpdateAccountData", "onHandleUpdateCompany", "onHandleUpdateGenerator", "onCreateCompanyIfMissing"]))
                  : _createCommentVNode("", true),
                (_ctx.selectedCountry === 'LV')
                  ? (_openBlock(), _createBlock(_component_LVSignupFlow, {
                      key: 1,
                      accountData: _ctx.accountData,
                      companyIndex: _ctx.companyIndex,
                      generatorIndex: _ctx.generatorIndex,
                      companyIdQueryString: _ctx.companyIdQueryString,
                      handleUpdateAccountData: _ctx.handleUpdateAccountData,
                      onHandleUpdateCompany: _ctx.handleUpdateCompany,
                      onHandleUpdateGenerator: _ctx.handleUpdateGenerator,
                      onCreateCompanyIfMissing: _ctx.createCompanyIfMissing
                    }, null, 8, ["accountData", "companyIndex", "generatorIndex", "companyIdQueryString", "handleUpdateAccountData", "onHandleUpdateCompany", "onHandleUpdateGenerator", "onCreateCompanyIfMissing"]))
                  : _createCommentVNode("", true),
                (_ctx.selectedCountry === 'LT')
                  ? (_openBlock(), _createBlock(_component_LTSignupFlow, {
                      key: 2,
                      accountData: _ctx.accountData,
                      companyIndex: _ctx.companyIndex,
                      generatorIndex: _ctx.generatorIndex,
                      companyIdQueryString: _ctx.companyIdQueryString,
                      handleUpdateAccountData: _ctx.handleUpdateAccountData,
                      onHandleUpdateCompany: _ctx.handleUpdateCompany,
                      onHandleUpdateGenerator: _ctx.handleUpdateGenerator,
                      onCreateCompanyIfMissing: _ctx.createCompanyIfMissing,
                      onRemoveEmptyCompanyIfExists: _ctx.removeEmptyCompanyIfExists
                    }, null, 8, ["accountData", "companyIndex", "generatorIndex", "companyIdQueryString", "handleUpdateAccountData", "onHandleUpdateCompany", "onHandleUpdateGenerator", "onCreateCompanyIfMissing", "onRemoveEmptyCompanyIfExists"]))
                  : _createCommentVNode("", true),
                (!_ctx.selectedCountry)
                  ? (_openBlock(), _createBlock(_component_EmptySignupFlow, {
                      key: 3,
                      accountData: _ctx.accountData,
                      companyIndex: _ctx.companyIndex,
                      generatorIndex: _ctx.generatorIndex,
                      companyIdQueryString: _ctx.companyIdQueryString,
                      handleUpdateAccountData: _ctx.handleUpdateAccountData,
                      onHandleUpdateCompany: _ctx.handleUpdateCompany,
                      onHandleUpdateGenerator: _ctx.handleUpdateGenerator,
                      onCreateCompanyIfMissing: _ctx.createCompanyIfMissing
                    }, null, 8, ["accountData", "companyIndex", "generatorIndex", "companyIdQueryString", "handleUpdateAccountData", "onHandleUpdateCompany", "onHandleUpdateGenerator", "onCreateCompanyIfMissing"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}