<!-- client/src/components/common/SlottedContainerComponent.vue -->

<template>
  <div class="border rounded-2 bg-body">
    <div class="p-2 pb-0">
      <div
        class="d-flex flex-column flex-md-row align-items-center justify-content-between p-1 border-0"
      >
        <slot name="title"></slot>
      </div>
      <hr v-if="!showCollapse" class="m-0 my-1" />
      <div v-show="showCollapse">
        <div class="border rounded-3 my-2 p-0 box-shadow overflow-hidden">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
    <div
      v-if="expandable"
      class="btn btn-text w-100 p-1 pt-0"
      @click="toggleCollapse()"
    >
      <font-awesome-icon :icon="showCollapse ? 'chevron-up' : 'chevron-down'" />
      {{ buttonText }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "SlottedContainerComponent",
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    expandable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const showCollapse = ref(true);

    const toggleCollapse = () => {
      showCollapse.value = !showCollapse.value;
    };

    const forceToggleCollapse = (value: boolean) => {
      showCollapse.value = value;
    };

    // Expose the method
    emit("setForceToggle", forceToggleCollapse);

    return {
      showCollapse,
      toggleCollapse,
    };
  },
});
</script>
