<!-- client/src/components/common/RegistryDevicesCardComponent.vue -->

<template>
  <div v-if="registryDevices.length > 0">
    <h4 class="mb-3">
      {{ $t("dashboard.companyData.production_settings") }}
    </h4>
    <div v-for="registryDevice in registryDevices" :key="registryDevice.id">
      <div class="card mb-2 registryDevice-card">
        <div class="card-body">
          <div>
            <h2 class="accordion-header" :id="'heading' + registryDevice.id">
              <a
                href="#"
                class="collapsed"
                type="accordion-button button"
                data-bs-toggle="collapse"
                :data-bs-target="'#collapse' + registryDevice.id"
                aria-expanded="false"
                :aria-controls="'collapse' + registryDevice.id"
              >
                <div class="card-title">
                  {{ $t("generator.EIC") }}: {{ registryDevice.device_eic }} -
                  {{
                    $t(
                      `registry_device.status.${registryDevice.registry_status}`
                    )
                  }}
                  <label
                    v-if="is_admin"
                    class="alert alert-subtle-warning text-center admin-extra-info"
                  >
                    {{ registryDevice.id }}
                  </label>
                </div>
              </a>
            </h2>
            <div
              :id="'collapse' + registryDevice.id"
              class="accordion-collapse collapse"
              :aria-labelledby="'heading' + registryDevice.id"
            >
              <div class="card-text">
                <div class="accordion border rounded p-2">
                  <div class="accordion-item">
                    <div class="accordion-body">
                      <div class="registryDevice-segment">
                        <div class="registryDevice-property">
                          {{ $t("registry_device.device_name") }}:
                        </div>
                        <div class="registryDevice-value">
                          {{ registryDevice.device_name }}
                        </div>
                      </div>
                      <div class="registryDevice-segment">
                        <div class="registryDevice-property">
                          {{ $t("registry_device.device_power") }}:
                        </div>
                        <div class="registryDevice-value">
                          {{ registryDevice.device_power }}
                        </div>
                      </div>
                      <div class="registryDevice-segment">
                        <div class="registryDevice-property">
                          {{ $t("registry_device.supported_device") }}?
                        </div>
                        <div class="registryDevice-value">
                          {{
                            registryDevice.support
                              ? $t("common.yes")
                              : $t("common.no")
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, inject } from "vue";
import { goSolidApi } from "@/composables/useGoSolidApi";
import type { RegistryDevice } from "../../types";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "RegistryDevicesCardComponent",
  props: {
    company_id: {
      type: Number,
      required: true,
    },
    is_admin: {
      type: Boolean,
      required: true,
    },
  },
  components: {},
  setup(props) {
    const { t } = useI18n();
    const isLoading = ref(false);
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;
    const registryDevices = ref<[RegistryDevice] | []>([]);
    async function fetchRegistryDevices() {
      isLoading.value = true;
      try {
        const response = await goSolidApi.get(
          `/api/company/registry-devices/${props.company_id}`
        );
        if (response.status === 200) {
          registryDevices.value = response.data;
        } else {
          throw new Error(t("registry_device.fetch_failed"));
        }
      } catch (error) {
        globalErrorHandler(error);
      } finally {
        isLoading.value = false;
      }
    }

    onMounted(() => {
      fetchRegistryDevices();
    });
    return {
      registryDevices,
    };
  },
});
</script>

<style scoped>
.registryDevice-card {
  .card-title {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .registryDevice-segment {
    .registryDevice-property {
      font-weight: 600;
      color: var(--phoenix-secondary-text-emphasis);
    }
    .registryDevice-value {
      margin-left: 1rem;
    }
    .badge {
      margin-left: 1rem;
    }
  }
  .accordion > .accordion-item:last-child {
    border-bottom: none;
  }
}
.admin-extra-info {
  padding: 0;
}
</style>
