<template>
  <div class="generator-license-review card p-4 mb-3" novalidate>
    <h3 class="mb-1 mb-sm-4">
      {{ $t("signup.generator_license_review.title") }}
    </h3>
    <p>
      {{ $t("signup.generator_license_review.description") }}
    </p>
    <p v-html="$t('signup.generator_license_review.directions')"></p>
    <div class="row mb-2 g-2">
      <label for="license-number" class="col-sm-3 mt-sm-3 col-form-label">
        {{ $t("generator.license_number") }}
      </label>
      <div class="col-sm-8 col-10">
        <input
          type="text"
          id="license-number"
          class="form-control"
          :class="{ 'is-invalid': v$.license_number.$error }"
          v-model="localGenerator.license_number"
          @blur="v$.license_number.$touch"
        />
        <div
          class="invalid-feedback"
          v-for="error of v$.license_number.$errors.filter(
            (_, index) => index < 1
          )"
          :key="error.$uid"
        >
          {{ error.$message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useValidators } from "@/composables/useValidators";
import { useVuelidate } from "@vuelidate/core";
import { useGeneratorFinder } from "@/composables/useGeneratorFinder";
import type { Account } from "@/types";

export default defineComponent({
  name: "GeneratorLicenseReviewComponent",
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
  },
  emits: ["handleUpdateGenerator"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { required } = useValidators(t);

    const { selectedGenerator } = useGeneratorFinder(props.accountData);
    const localGenerator = reactive({
      ...selectedGenerator.value.generator,
    });

    const rules = computed(() => ({
      license_number: { required },
    }));

    const v$ = useVuelidate(rules, localGenerator);

    watch(localGenerator, (newValue) => {
      emit("handleUpdateGenerator", newValue);
    });

    return {
      localGenerator,
      v$,
    };
  },
});
</script>

<style scoped>
.generator-license-review {
  .col-form-label {
    text-align: left;
    font-weight: bold;
    padding: 0;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 575.98px) {
  .generator-license-review {
    .col-form-label {
      margin-top: 1rem;
    }
  }
  h3 {
    font-size: 1.2rem;
  }
}
</style>
