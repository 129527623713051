<!-- client/src/views/SignUpView.vue -->

<template>
  <div class="signup-view">
    <div v-if="isAccountDataReady" class="container py-5 pt-3 pt-xl-5">
      <div v-if="isAccountDataReady">
        <EESignupFlow
          v-if="selectedCountry === 'EE'"
          :accountData="accountData"
          :companyIndex="companyIndex"
          :generatorIndex="generatorIndex"
          :companyIdQueryString="companyIdQueryString"
          :handleUpdateAccountData="handleUpdateAccountData"
          @handleUpdateCompany="handleUpdateCompany"
          @handleUpdateGenerator="handleUpdateGenerator"
          @createCompanyIfMissing="createCompanyIfMissing"
        />

        <LVSignupFlow
          v-if="selectedCountry === 'LV'"
          :accountData="accountData"
          :companyIndex="companyIndex"
          :generatorIndex="generatorIndex"
          :companyIdQueryString="companyIdQueryString"
          :handleUpdateAccountData="handleUpdateAccountData"
          @handleUpdateCompany="handleUpdateCompany"
          @handleUpdateGenerator="handleUpdateGenerator"
          @createCompanyIfMissing="createCompanyIfMissing"
        />

        <LTSignupFlow
          v-if="selectedCountry === 'LT'"
          :accountData="accountData"
          :companyIndex="companyIndex"
          :generatorIndex="generatorIndex"
          :companyIdQueryString="companyIdQueryString"
          :handleUpdateAccountData="handleUpdateAccountData"
          @handleUpdateCompany="handleUpdateCompany"
          @handleUpdateGenerator="handleUpdateGenerator"
          @createCompanyIfMissing="createCompanyIfMissing"
          @removeEmptyCompanyIfExists="removeEmptyCompanyIfExists"
        />

        <EmptySignupFlow
          v-if="!selectedCountry"
          :accountData="accountData"
          :companyIndex="companyIndex"
          :generatorIndex="generatorIndex"
          :companyIdQueryString="companyIdQueryString"
          :handleUpdateAccountData="handleUpdateAccountData"
          @handleUpdateCompany="handleUpdateCompany"
          @handleUpdateGenerator="handleUpdateGenerator"
          @createCompanyIfMissing="createCompanyIfMissing"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { useCompanyFinder } from "@/composables/useCompanyFinder";
import { useGeneratorFinder } from "@/composables/useGeneratorFinder";
import type { Account, Company } from "@/types";
import { fetchAccount } from "@/composables/account/fetchAccount";
import { watchEffectOnceAsync } from "@/composables/watchEffect";
import EESignupFlow from "@/components/signup/ee/EESignupFlow.vue";
import LVSignupFlow from "@/components/signup/lv/LVSignupFlow.vue";
import EmptySignupFlow from "@/components/signup/EmptySignupFlowComponent.vue";
import { useSelectedCountry } from "@/composables/useSelectedCountry";
import LTSignupFlow from "@/components/signup/lt/LTSignupFlow.vue";

export default defineComponent({
  components: {
    EESignupFlow,
    LVSignupFlow,
    LTSignupFlow,
    EmptySignupFlow,
  },
  setup() {
    const { accountData } = fetchAccount();
    const createContainerForStartSigningFunction = ref(() => undefined);
    const { selectedCountry, ensureCountryChosen } =
      useSelectedCountry(accountData);

    const {
      companyIndex,
      companyIdQueryString,
      findCompanyIndexWithoutId,
      createCompanyIfMissing,
      removeEmptyCompanyIfExists,
    } = useCompanyFinder(accountData);
    const company = computed(() => {
      return accountData.companies[companyIndex.value];
    });
    const { generatorIndex, nullableSelectedGenerator } =
      useGeneratorFinder(accountData);

    const isAccountDataReady = computed(() => {
      return accountData && accountData.email; // or any other field that indicates data is loaded
    });

    const handleUpdateAccountData = (updatedAccountData: Account) => {
      Object.assign(accountData, updatedAccountData);
    };
    const handleUpdateCompany = (updatedCompany: Company) => {
      Object.assign(accountData.companies[companyIndex.value], updatedCompany);
    };
    const handleUpdateGenerator = (updatedGenerator: Generator) => {
      Object.assign(
        accountData.companies[companyIndex.value].generators[
          generatorIndex.value
        ],
        updatedGenerator
      );
    };

    onMounted(async () => {
      if (!isAccountDataReady.value) {
        await watchEffectOnceAsync(() => isAccountDataReady.value);

        ensureCountryChosen();
      }
    });

    return {
      companyIndex,
      companyIdQueryString,
      company,
      generatorIndex,
      nullableSelectedGenerator,
      accountData,
      handleUpdateAccountData,
      createContainerForStartSigningFunction,
      isAccountDataReady,
      findCompanyIndexWithoutId,
      handleUpdateCompany,
      handleUpdateGenerator,
      createCompanyIfMissing,
      selectedCountry,
      removeEmptyCompanyIfExists,
    };
  },
});
</script>

<style scoped lang="scss">
.signup-view {
  overflow-y: auto;
  // Custom container styles
  .container {
    max-width: 800px;
  }
}
</style>
