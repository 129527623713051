<!-- client/src/components/signup/lt/CompanyInfoReviewConfigurationComponent.vue -->

<template>
  <ContactReviewDisplayComponent
    :accountData="accountData"
    :companyIndex="companyIndex"
    :editableAccountFields="accountFields"
    :editableCompanyFields="companyFields"
    :shouldShowCompanyFields="shouldShowCompanyFields"
    :enableAutofill="false"
    :existingCompanies="[]"
    :company_section_title="$t('signup.lt.extra_info.title')"
    :company_section_description="$t('signup.lt.extra_info.description')"
    @updateAccountData="$emit('updateAccountData', $event)"
    @handleUpdateCompany="$emit('handleUpdateCompany', $event)"
    @selectExistingCompany="$emit('selectExistingCompany', $event)"
    @resetCompanySelection="$emit('resetCompanySelection')"
  />
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import type { Account, ContactFieldType } from "@/types";
import { useValidators } from "@/composables/useValidators";
import { useCompanyFinder } from "@/composables/useCompanyFinder";
import { useI18n } from "vue-i18n";
import ContactReviewDisplayComponent from "@/components/signup/ContactReviewDisplayComponent.vue";

export default defineComponent({
  name: "CompanyInfoReviewConfigurationComponent",
  components: {
    ContactReviewDisplayComponent,
  },
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
    companyIndex: {
      type: Number,
      required: false,
    },
  },
  emits: [
    "updateAccountData",
    "handleUpdateCompany",
    "selectExistingCompany",
    "resetCompanySelection",
  ],
  setup(props) {
    const { t } = useI18n();
    const { required, lithuanianCompanyNumber, personalName, phoneNumber } =
      useValidators(t);

    const { selectedCompany } = useCompanyFinder(props.accountData);
    const company = selectedCompany.value;

    const accountFields: ContactFieldType[] = [];

    const companyFields: ContactFieldType[] = [
      {
        label: t("company.vat"),
        id: "company-vat",
        model: "vat",
        type: "text",
        editable: true,
        validators: { required },
      },
      {
        label: t("company.bank_account"),
        id: "company-bank-account",
        model: "bank_account",
        type: "text",
        editable: true,
        validators: [required],
      },
      {
        label: t("company.bank_name"),
        id: "company-company-bank-name",
        model: "bank_name",
        type: "text",
        editable: true,
        validators: { required },
      },
      {
        label: t("company.bank_swift"),
        id: "company-company-bank-swift",
        model: "bank_swift",
        type: "text",
        editable: true,
        validators: { required },
      },
    ];

    const shouldShowCompanyFields = computed(() => {
      return true;
    });

    return {
      accountFields,
      companyFields,
      shouldShowCompanyFields,
      company,
    };
  },
});
</script>

<style scoped></style>
