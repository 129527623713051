<!-- client/src/components/dashboard/InvoiceHistoryComponent.vue -->

<template>
  <div class="invoice-history-component">
    <h4 class="fs-7 fw-bold mb-3">{{ $t("invoiceHistory.title") }}</h4>
    <div
      v-if="invoices.length === 0"
      class="text-center card p-2 bg-secondary-subtle"
    >
      <p class="fs-8 m-1 fw-bold">{{ $t("invoiceHistory.noInvoices") }}</p>
      <p class="fs-8 m-1">
        {{ $t("invoiceHistory.notificationInfo") }}
      </p>
    </div>
    <div class="d-flex-row">
      <InvoiceDisplayComponent
        v-for="invoice in invoices"
        :key="invoice.id"
        :invoice="invoice"
        :is_admin="is_admin"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, inject } from "vue";
import { Invoice } from "@/types";
import InvoiceDisplayComponent from "@/components/common/InvoiceDisplayComponent.vue";
import { goSolidApi } from "@/composables/useGoSolidApi";

export default defineComponent({
  name: "InvoiceHistoryComponent",
  components: {
    InvoiceDisplayComponent,
  },
  props: {
    company_id: {
      type: Number,
      required: true,
    },
    is_admin: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const invoices = ref<Invoice[]>([]);
    const globalErrorHandler = inject("globalErrorHandler") as (
      error: any
    ) => void;

    async function getInvoices(company_id: number) {
      try {
        const response = await goSolidApi.get(
          `/api/company/${company_id}/invoices`
        );
        if (response.status === 200) {
          invoices.value = response.data.invoices.sort(
            (a: Invoice, b: Invoice) => {
              // Invoices without requested_at field come first
              if (!a.requested_at) return -1;
              if (!b.requested_at) return 1;
              // Then, sort by requested_at date from newest to oldest
              return (
                new Date(b.requested_at).getTime() -
                new Date(a.requested_at).getTime()
              );
            }
          );
        } else {
          throw new Error("Error fetching invoices");
        }
      } catch (error: any) {
        globalErrorHandler(error.response.data);
      }
    }

    onMounted(() => {
      getInvoices(props.company_id);
    });

    return { invoices };
  },
});
</script>

<style scoped></style>
