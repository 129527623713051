<!-- client/src/components/signup/lt/LTSignupFlow.vue -->

<template>
  <div class="container py-5 pt-3 pt-xl-5">
    <progress-nav-component
      v-if="!changingInformationDisabled"
      :steps="navSteps"
      :setStep="setStep"
    ></progress-nav-component>

    <CountryPickerComponent
      v-if="signupStep === 1"
      :accountData="accountData"
      :handleUpdateAccountData="handleUpdateAccountData"
    ></CountryPickerComponent>

    <ChooseOnboardingComponent
      v-if="shownStep.component === 'choose-onboarding'"
      :addAuthorizationAgreementFlow="createAuthorizationAccountFlow"
      :createNewAccountFlow="createNewAccountFlow"
    >
    </ChooseOnboardingComponent>

    <!-- Conditionally Rendered Components -->
    <MultipleUploadChecklistComponent
      v-if="shownStep.component === 'multiple-upload'"
      :accountData="accountData"
      :hasGenerator="hasGenerator"
      :setLoading="setLoading"
      :addAuthorizationAgreement="addAuthorizationAgreement"
      @uploadComplete="handleUploadComplete"
      class="mb-4"
    ></MultipleUploadChecklistComponent>

    <ContactReviewConfigurationComponent
      v-if="shownStep.component === 'contact-review' && companyIndex !== -1"
      :key="companyIndex"
      :accountData="accountData"
      :companyIndex="companyIndex"
      @handleUpdateCompany="$emit('handleUpdateCompany', $event)"
      @updateAccountData="handleUpdateAccountData"
      @selectExistingCompany="handleSelectExistingCompany"
      @resetCompanySelection="handleResetCompanySelection"
      class="mb-4"
    ></ContactReviewConfigurationComponent>

    <GeneratorLicenseReviewComponent
      v-if="
        shownStep.component === 'generator-license' && generatorIndex !== -1
      "
      :accountData="accountData"
      @handleUpdateGenerator="$emit('handleUpdateGenerator', $event)"
      class="mb-4"
    ></GeneratorLicenseReviewComponent>

    <CompanyInfoReviewConfigurationComponent
      v-if="shownStep.component === 'generator-license' && companyIndex !== -1"
      :key="companyIndex"
      :accountData="accountData"
      :companyIndex="companyIndex"
      @handleUpdateCompany="$emit('handleUpdateCompany', $event)"
      @updateAccountData="handleUpdateAccountData"
      @selectExistingCompany="handleSelectExistingCompany"
      @resetCompanySelection="handleResetCompanySelection"
      class="mb-4"
    ></CompanyInfoReviewConfigurationComponent>

    <ContractReviewConfigurationComponent
      v-if="shownStep.component === 'contract-review' && companyIndex !== -1"
      :accountData="accountData"
      :setLoading="setLoading"
      @createContainerForStartSigningReady="storeCreateContainerForStartSigning"
      @disableChangingInformation="handleDisableChangingInformation"
      class="mb-4"
    ></ContractReviewConfigurationComponent>

    <!-- Display Error Message -->
    <div v-if="apiError" class="alert alert-danger">
      {{ apiErrorMessage }}
    </div>

    <!-- Buttons -->
    <div
      v-if="
        shownStep.component !== 'choose-onboarding' &&
        !changingInformationDisabled
      "
      class="d-flex justify-content-between w-100"
    >
      <button
        class="btn btn-secondary me-2"
        :disabled="signupStep === 1 || isLoading"
        @click="setStep(signupStep - 1)"
      >
        {{ $t("signup.navsteps.back") }}
      </button>
      <button
        v-if="shownStep.component !== 'contract-review'"
        class="btn btn-primary"
        :disabled="isLoading"
        @click="setStep(signupStep + 1)"
      >
        {{ $t("signup.navsteps.next") }}
      </button>
      <button
        v-if="shownStep.component === 'contract-review'"
        class="btn btn-primary"
        :disabled="isLoading"
        @click="createContainerForStartSigningFunction"
      >
        {{ $t("signup.navsteps.to_signature_page") }}
      </button>
    </div>
    <!-- Loading overlay, shown only when isLoading is true -->
    <LoadingOverlayComponent v-if="isLoading" />
    <!-- AccountDataDebugComponent for debugging -->
    <AccountDataDebugComponent
      :accountData="accountData"
      class="mb-4"
    ></AccountDataDebugComponent>
  </div>
  <!-- Greeting modal -->
  <ModalComponent
    :submitButtonText="$t('signup.modal.hello_button')"
    @setForceToggle="setToggleHelloModal"
  >
    <template #title>{{ $t("signup.modal.hello") }}</template>
    <template #body>
      <p class="text-body-tertiary lh-lg">
        {{ $t("signup.modal.lt.section1") }}
      </p>
      <p>
        {{ $t("signup.modal.lt.section2") }}
        <a
          class="link"
          role="button"
          href="https://www.soldera.org/lt/#faq"
          target="blank"
        >
          <font-awesome-icon icon="right-to-bracket" />
          {{ $t("signup.modal.soldera_faq") }}
        </a>
      </p>
      <div class="accordion mt-3" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              {{ $t("signup.modal.lt.section3") }}
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                {{ $t("signup.modal.lt.section4") }}
              </p>
              <p>
                {{ $t("signup.modal.lt.section5") }}
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              {{ $t("signup.modal.lt.section6") }}
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                {{ $t("signup.modal.lt.section7") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ModalComponent>
</template>

<script lang="ts">
import { defineComponent, watch, ref, computed, onMounted } from "vue";
import AccountDataDebugComponent from "@/components/common/AccountDataDebugComponent.vue";
import LoadingOverlayComponent from "@/components/common/LoadingOverlayComponent.vue";
import ContactReviewConfigurationComponent from "@/components/signup/lt/ContactReviewConfigurationComponent.vue";
import CompanyInfoReviewConfigurationComponent from "@/components/signup/lt/CompanyInfoReviewConfigurationComponent.vue";
import ContractReviewConfigurationComponent from "@/components/signup/lt/ContractReviewConfigurationComponent.vue";
import GeneratorLicenseReviewComponent from "@/components/signup/lt/GeneratorLicenseReviewComponent.vue";
import ProgressNavComponent from "@/components/signup/ProgressNavComponent.vue";
import ModalComponent from "@/components/common/ModalComponent.vue";
import type { Account, Step } from "@/types";
import { useRoute, useRouter } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { accountUpdater } from "@/composables/account/accountUpdater";
import CountryPickerComponent from "@/components/signup/CountryPickerComponent.vue";
import { useI18n } from "vue-i18n";
import MultipleUploadChecklistComponent from "./MultipleUploadChecklistComponent.vue";
import ChooseOnboardingComponent from "@/components/signup/ChooseOnboardingComponent.vue";

export default defineComponent({
  name: "LTSignupFlow",
  components: {
    AccountDataDebugComponent,
    ContactReviewConfigurationComponent,
    CompanyInfoReviewConfigurationComponent,
    ContractReviewConfigurationComponent,
    ProgressNavComponent,
    LoadingOverlayComponent,
    ModalComponent,
    CountryPickerComponent,
    MultipleUploadChecklistComponent,
    GeneratorLicenseReviewComponent,
    ChooseOnboardingComponent,
  },
  props: {
    accountData: {
      type: Object as () => Account,
      required: true,
    },
    handleUpdateAccountData: {
      type: Function as unknown as () => (updatedAccountData: Account) => void,
      required: true,
    },
    companyIndex: {
      type: Number,
      required: true,
    },
    generatorIndex: {
      type: Number,
      required: true,
    },
    companyIdQueryString: {
      type: Number,
      required: true,
    },
  },
  emits: [
    "handleUpdateCompany",
    "handleUpdateGenerator",
    "createCompanyIfMissing",
    "removeEmptyCompanyIfExists",
  ],
  setup(props, { emit }) {
    const createContainerForStartSigningFunction = ref(() => undefined);

    const isLoading = ref(true);
    const apiError = ref(false);
    const apiErrorMessage = ref("");
    const route = useRoute();
    const router = useRouter();
    const signupStep = ref(
      route.query.step ? parseInt(route.query.step.toString()) : 1
    );
    const addAuthorizationAgreement = computed(() => {
      return route.query.addAuthorizationAgreement === "true";
    });
    const v$ = useVuelidate();
    const { t } = useI18n();

    const storeCreateContainerForStartSigning = (
      createContainerForStartSigning: () => undefined
    ) => {
      createContainerForStartSigningFunction.value =
        createContainerForStartSigning;
    };

    const changingInformationDisabled = ref(false);
    const handleDisableChangingInformation = () => {
      changingInformationDisabled.value = true;
    };
    const toggleHelloModal = ref();
    const setToggleHelloModal = (method: (value: boolean) => null) => {
      toggleHelloModal.value = method;
    };
    const toggleHelloIfNew = async () => {
      if (props.accountData.companies.length === 0) {
        toggleHelloModal.value(true);
      }
    };
    const setLoading = (value: boolean) => {
      isLoading.value = value;
    };

    const fetchUrl = () => {
      if (shownStep.value.component === "contact-review") {
        return "/api/lt/update-company";
      } else if (shownStep.value.component === "generator-license") {
        return "/api/lt/update-generator";
      } else {
        throw new Error("Invalid step for account " + props.accountData.id);
      }
    };

    const onAccountUpdated = async (_: Account, company_id: number) => {
      isLoading.value = false;

      // This is here to only add the new company_id to the query parameters in case of authorization
      // agreement when company was created
      const newQuery = {
        ...route.query,
        company_id: company_id,
      };
      await router.push({ name: "SignUp", query: newQuery });
    };

    const { postAccountData } = accountUpdater(
      props.accountData,
      props.handleUpdateAccountData
    );

    const checkValidity = () => {
      v$.value.$touch();
      return !v$.value.$error;
    };

    const setStep = async (step: number) => {
      isLoading.value = true;
      try {
        if (!checkValidity()) {
          if (
            !(
              shownStep.value.component === "multiple-upload" &&
              step < signupStep.value
            )
          ) {
            return;
          }
        } else {
          // Since data is valid we can post it
          if (
            shownStep.value.component === "contact-review" ||
            shownStep.value.component === "generator-license"
          ) {
            await postAccountData(
              fetchUrl(),
              onAccountUpdated,
              props.companyIdQueryString
            );
          }
        }

        signupStep.value = step;

        // Preserve the existing query parameters
        if (step === 1) {
          const newQuery = {
            ...route.query,
            addAuthorizationAgreement: undefined,
            step: step.toString(),
          };
          router.push({ name: "SignUp", query: newQuery });
        } else {
          const newQuery = {
            ...route.query,
            step: step.toString(),
          };
          router.push({ name: "SignUp", query: newQuery });
        }
      } finally {
        isLoading.value = false;
      }
    };

    // Details which step is shown and whether or not the "next" button is enabled on that screen
    const shownStep = computed(() => {
      if (addAuthorizationAgreement.value) {
        if (signupStep.value === 1) {
          return { component: "contact-review" };
        } else if (signupStep.value === 2) {
          return {
            component: "choose-onboarding",
          };
        } else if (signupStep.value === 3) {
          return {
            component: "multiple-upload",
          };
        } else if (signupStep.value === 4) {
          return {
            component: "contract-review",
          };
        }
      } else {
        if (signupStep.value === 1) {
          return { component: "contact-review" };
        } else if (signupStep.value === 2) {
          return {
            component: "choose-onboarding",
          };
        } else if (signupStep.value === 3) {
          return {
            component: "multiple-upload",
          };
        } else if (signupStep.value === 4) {
          return {
            component: "generator-license",
          };
        } else if (signupStep.value === 5) {
          return {
            component: "contract-review",
          };
        }
      }
      return { component: "contact-review" };
    });

    const navCheck = (step: number) =>
      computed(() => {
        if (step === 1) {
          return true;
        } else {
          return signupStep.value - step >= -1;
        }
      });

    const navSteps = computed(() => {
      return [
        {
          title: addAuthorizationAgreement.value
            ? t("signup.navsteps.lt.authorization.1")
            : t("signup.navsteps.lt.new_device.1"),
          isAvailable: navCheck(1).value,
        },
        {
          title: addAuthorizationAgreement.value
            ? t("signup.navsteps.lt.authorization.2")
            : t("signup.navsteps.lt.new_device.2"),
          isAvailable: navCheck(2).value || navCheck(3).value,
        },
        {
          title: addAuthorizationAgreement.value
            ? t("signup.navsteps.lt.authorization.3")
            : t("signup.navsteps.lt.new_device.3"),
          isAvailable: navCheck(4).value,
        },
        {
          title: addAuthorizationAgreement.value
            ? t("signup.navsteps.lt.authorization.4")
            : t("signup.navsteps.lt.new_device.4"),
          isAvailable: navCheck(4).value,
        },
        {
          title: addAuthorizationAgreement.value
            ? null
            : t("signup.navsteps.lt.new_device.5"),
          isAvailable: navCheck(5).value,
        },
      ].filter(Boolean) as Step[]; // Remove null values
    });

    const hasGenerator = computed(() => {
      return props.generatorIndex !== -1;
    });

    const handleUploadComplete = async (responseData: {
      accountData: Account;
      generator_id: string;
      company_id: string;
    }) => {
      const newAccountData = responseData.accountData;
      const generator_id = responseData.generator_id;
      const company_id = responseData.company_id;
      props.handleUpdateAccountData(newAccountData);
      const newQuery = {
        ...route.query,
        generator_id: generator_id,
        company_id: company_id,
      };
      router.push({ name: "SignUp", query: newQuery });
    };

    const createAuthorizationAccountFlow = () => {
      const newQuery = {
        ...route.query,
        addAuthorizationAgreement: "true",
        step: "3",
      };
      router.push({ name: "SignUp", query: newQuery });
    };

    const createNewAccountFlow = () => {
      const newQuery = {
        ...route.query,
        addAuthorizationAgreement: "false",
        step: "3",
      };
      router.push({ name: "SignUp", query: newQuery });
    };

    const handleSelectExistingCompany = async (companyId: number) => {
      isLoading.value = true;
      try {
        const newQuery = {
          ...route.query,
          company_id: companyId.toString(),
        };
        await router.push({ query: newQuery });
        emit("removeEmptyCompanyIfExists");
      } catch (error) {
        apiError.value = true;
        apiErrorMessage.value = "Error selecting company";
      } finally {
        isLoading.value = false;
      }
    };

    const handleResetCompanySelection = () => {
      emit("createCompanyIfMissing");
      const newQuery = {
        ...route.query,
        company_id: undefined,
        generator_id: undefined,
      };
      router.push({ query: newQuery });
    };

    onMounted(async () => {
      if (
        shownStep.value.component === "contact-review" &&
        props.companyIndex === -1
      ) {
        emit("createCompanyIfMissing");
      }
      isLoading.value = false;
      // toggleHelloIfNew(); TODO add translations and stuff
    });

    watch(
      () => route.query.step,
      (newStep) => {
        if (typeof newStep === "string") {
          const stepNum = parseInt(newStep, 10);
          if (!isNaN(stepNum) && stepNum >= 0 && stepNum <= 4) {
            signupStep.value = stepNum;
          }
        }
      },
      { immediate: true }
    );

    return {
      navSteps,
      shownStep,
      signupStep,
      setStep,
      isLoading,
      setLoading,
      apiError,
      apiErrorMessage,
      hasGenerator,
      handleUploadComplete,
      setToggleHelloModal,
      storeCreateContainerForStartSigning,
      createContainerForStartSigningFunction,
      addAuthorizationAgreement,
      createAuthorizationAccountFlow,
      createNewAccountFlow,
      handleSelectExistingCompany,
      handleResetCompanySelection,
      handleDisableChangingInformation,
      changingInformationDisabled,
    };
  },
});
</script>
